import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import EstimateServiceModel from '/src/models/estimate_service'
import ScopeModel from '/src/models/scope'
import ScopingServiceCellFactory from '/src/ui/domain/estimate_services/scoping_service_cell_factory'
import { buildBackURL } from '/src/utils/url'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import useExcelExportUrl from '/src/hooks/api/export_excel_url'
import useGridFilterSelection from '/src/hooks/grid_filter_selection'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import NewButton from '/src/ui/core/icons/new_button'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import GridFilterIcon from '/src/ui/core/icons/grid_filter_icon'
import VerticalDivider from '/src/ui/core/icons/vertical_divider'
import BulkEditingIcon from '/src/ui/core/icons/bulk_editing_icon'
import InspectionsIcon from '/src/ui/core/icons/inspections_icon'
import DuplicateServicesIcon from '/src/ui/core/icons/duplicate_services_icon'
import GridEmptyState from '/src/ui/core/grid/grid_empty_state'
import EmptyStateButton from '/src/ui/core/layouts/empty_state_button'
import { isPresent } from '/src/utils/boolean_refinements'
import {
  importMenuItem,
  exportMenuItem,
  printMenuItem,
  clearTemplatePreferencesMenuItem,
  printListMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import useConfigurePrintPDF from '/src/ui/core/popups/configure_print_pdf'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import useEstimateStatusFilter from '/src/ui/domain/estimate_services/estimate_service_status_filter'
import StatusFilterLabel from '/src/ui/core/grid/statuses_filter_label'
import { translateFormulaServiceColumns } from '/src/ui/domain/formulas_services/formulas_services_utils'
import { CONTEXT_MENU_HIDDEN_STATUS as notEditableStatuses } from '/src/utils/constants/scope'
import { injectProperty } from '/src/utils/object'
import I18n from '/src/utils/translations.js'
import '/src/static/css/core/grid/grid.css'

const injectRequestAndParent = (dataSource, parentItem) => {
  if (!dataSource?.data || !parentItem?.request) return dataSource

  const dataWithRequest = injectProperty(dataSource.data, 'request', parentItem.request)
  const dataWithParent = injectProperty(dataWithRequest, 'scope', parentItem)

  return {
    ...dataSource,
    data: dataWithParent
  }
}

const estimateServiceModel = new EstimateServiceModel()

// eslint-disable-next-line max-lines-per-function
export default function EstimateServicesGrid({
  dataItem,
  selectedItem,
  onRowClick,
  template,
  bulkEditItemVisible,
  onBulkEditItemClick,
  onDataSource,
  onGridColumns,
  contextMenuItems,
  isPrimaryGrid,
  eavSections,
  scopeTemplate
}) {
  const history = useHistory()
  const [gridColumns, setGridColumns] = useState([])
  const [gridDataSource, setGridDataSource] = useState()
  const [filter, setFilter] = useState()
  const [openImport, setOpenImport] = useState(false)
  const readOnlyMode = notEditableStatuses.includes(dataItem?.request?.request_status_id)
  const exportParams = {
    entity: 'estimate_services',
    templateId: template && template.id ? template.id : '',
    isApi: true
  }
  const { linkExport, setExportFilters } = useExcelExportUrl(exportParams)

  const { filter: requestsFilter, labels: requestLabels } = useRequestGridFilter({
    route: ScopeModel.route,
    templateId: template?.id,
    parent: 'estimate'
  })

  const beforeReload = () => {
    const location = new ScopeModel().url
    const backURLConfig = {
      eavTemplateId: dataItem.eav_template_id,
      selectItemId: dataItem.id
    }
    history.push(`/${location}?${buildBackURL(backURLConfig)}`)
  }

  const expandedGridDataSource = injectRequestAndParent(gridDataSource, dataItem)

  const [openConfigurationPopup, printGridPopup, isPrintable, printList] = useConfigurePrintPDF(
    gridColumns,
    expandedGridDataSource,
    estimateServiceModel
  )

  const { statusFilterButton, selectedStatuses, clearFilters, statusFilter } = useEstimateStatusFilter()

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(
    template ? template.id : undefined,
    beforeReload
  )

  const moreActionsMenuItems =
    template && template.id
      ? [
          printMenuItem(openConfigurationPopup, () => isPrintable),
          printListMenuItem(printList, () => isPrintable),
          clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick),
          importMenuItem(setOpenImport),
          exportMenuItem(linkExport)
        ]
      : []

  const onFilterSelection = useGridFilterSelection(setFilter)

  const toggleViewObj = {
    type: 'toggle_view',
    path: 'scopes',
    title: I18n.t('actions.alternate_view'),
    extraClasses: 'selected-filter'
  }

  const clearAllFiltersCallback = useCallback(() => {
    clearFilters()
  }, [clearFilters])

  const icons = [
    template && template.id && (
      <GridFilterIcon template={template} model="EstimateService" onFilterSelection={onFilterSelection} />
    ),
    'batch_assigner',
    <InspectionsIcon
      key="inspections-icon"
      modelName={estimateServiceModel.paramName}
      injectedData={dataItem?.request ? { request: dataItem?.request } : {}}
    />,
    <DuplicateServicesIcon
      model={estimateServiceModel}
      scopeTemplate={scopeTemplate}
      eavSections={eavSections}
      dataItem={dataItem}
    />,
    statusFilterButton,
    <VerticalDivider />,
    isPrimaryGrid && toggleViewObj,
    template && template.id && bulkEditItemVisible(isPrimaryGrid ? 'top' : 'bottom') && (
      <BulkEditingIcon onClick={() => onBulkEditItemClick(isPrimaryGrid ? 'top' : 'bottom')} />
    ),
    <MoreActionsIcon items={moreActionsMenuItems} />,
    !isPrimaryGrid && <NewButton modelName={estimateServiceModel.paramName} />
  ]

  const onClosePopupImport = () => {
    setOpenImport(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  const onSetGridColumns = (columns) => {
    setGridColumns(columns)
    if (onGridColumns) onGridColumns(columns)
  }

  const onSetGridDataSource = (dataSource) => {
    dataSource.data.map(
      (data) =>
        // eslint-disable-next-line no-param-reassign
        (data.checkboxDisabled = notEditableStatuses.includes(data?.request?.request_status_id))
    )

    setGridDataSource(dataSource)
    if (onDataSource) onDataSource(dataSource)
  }

  if (template) translateFormulaServiceColumns(estimateServiceModel, template.id)

  const combinedLabels = useMemo(() => {
    const labels = []

    if (isPrimaryGrid) {
      if (requestLabels) labels.push(...requestLabels)
    }

    if (selectedStatuses && selectedStatuses.length > 0) {
      labels.push(
        <StatusFilterLabel key="scaffolding-status-filter" options={selectedStatuses} onClearFilters={clearFilters} />
      )
    }

    return labels
  }, [requestLabels, selectedStatuses, clearFilters, isPrimaryGrid])

  useEffect(() => {
    const itemId = dataItem?.id
    const combinedFilters = []

    if (itemId) {
      combinedFilters.push({ type: 'where', column: 'scope_id', value: itemId })
    }

    if (isPrimaryGrid) {
      combinedFilters.push({ type: 'where', column: 'eav_template_id', value: template?.id })
      if (requestsFilter && requestsFilter.length > 0) {
        combinedFilters.push(...requestsFilter)
      }
    }

    if (statusFilter) {
      combinedFilters.push(statusFilter)
    }

    setFilter(combinedFilters.length > 0 ? combinedFilters : undefined)
  }, [dataItem?.id, template?.id, requestsFilter, statusFilter, isPrimaryGrid])

  const EmptyState = isPrimaryGrid ? (
    <div className="entity-grid-wrapper">
      <GridEmptyState
        modelName={`${estimateServiceModel.name} templates`}
        message={I18n.t('empty_state.no_estimate_service_template')}
      >
        <EmptyStateButton path="/scopes" label={I18n.t('scopes.title')} />
      </GridEmptyState>
    </div>
  ) : null

  return (isPresent(dataItem) || isPrimaryGrid) && template && template.id ? (
    <div className={isPrimaryGrid ? 'maingrid--height' : 'subgrid'} data-testid="scoping-service-grid">
      {openImport && template && (
        <PopupImportFile
          modelRoute={estimateServiceModel.route}
          templateId={template.id}
          onClose={onClosePopupImport}
        />
      )}
      {printGridPopup}
      {clearTemplatePreferencesPopup()}
      <SimpleGrid
        labels={combinedLabels}
        loadFlexColumns
        model={estimateServiceModel}
        sort={[{ field: 'id', dir: 'desc' }]}
        contextMenuItems={contextMenuItems}
        filter={filter}
        clearAllFiltersCallback={clearAllFiltersCallback}
        gridTitle={estimateServiceModel.name}
        templateId={template && template.id ? template.id : undefined}
        columnCellFactory={<ScopingServiceCellFactory />}
        selectedItem={selectedItem}
        onRowClick={onRowClick}
        onDataSource={onSetGridDataSource}
        onGridColumns={onSetGridColumns}
        selectFiltering={false}
        icons={icons}
        onFilterUpdate={setExportFilters}
        isSubGrid={!isPrimaryGrid}
        selecting={!readOnlyMode}
      />
    </div>
  ) : (
    EmptyState
  )
}

EstimateServicesGrid.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  onRowClick: PropTypes.func,
  onBulkEditItemClick: PropTypes.func.isRequired,
  onGridColumns: PropTypes.func.isRequired,
  onDataSource: PropTypes.func.isRequired,
  bulkEditItemVisible: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ).isRequired,
  template: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  isPrimaryGrid: PropTypes.bool
}

EstimateServicesGrid.defaultProps = {
  dataItem: undefined,
  selectedItem: undefined,
  onRowClick: () => {},
  isPrimaryGrid: false
}
