import React, { useState } from 'react'
import { MdControlPoint } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import useFetch from '/src/hooks/api/fetch'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import ChooseFormModal from '/src/ui/core/popups/choose_form_modal'
import FilterStepsModal from '/src/ui/core/popups/filter_steps_modal'
import { getServicesParams, getScopeFilterQuery, getUniqueRequestIds } from '/src/ui/domain/scopes/duplicate_services_utils'
import { getDescriptionField } from '/src/models/concerns/eav_section'
import '/src/static/css/core/rich_text/rich_text_preview.css'

export default function useDuplicateServices(scopeTemplateDisciplineId, eavSections, mode = 'scope') {
  const { fetch } = useFetch()

  const [requestStatuses] = useStore('request_statuses')
  const [scopeForDuplicate, setScopeForDuplicate] = useState(null)
  const [filters, setFilters] = useState([])

  const validRequestStatuses = Object.values(requestStatuses)
    .filter((status) => ['confirmed', 'in_scoping', 'clustered'].includes(status.i18n_id))
    .map((status) => status.id)

  const requestFilter = scopeForDuplicate && {
    searchRoute: 'requests',
    distinct: false,
    filterAttribute: 'request_id',
    keyField: 'id',
    queryType: 'where',
    required: true,
    searchFields: ['id', 'reason', 'comments'],
    searchOperator: 'containsOr',
    textDisplayFields: ['id', 'reason', 'comments'],
    title: I18n.t('requests.description'),
    query: {
      where: { request_status_id: validRequestStatuses, discipline_id: scopeTemplateDisciplineId }
    }
  }

  const firstFlexibleColumn = getDescriptionField(eavSections)

  const scopeFilter = scopeForDuplicate && {
    searchRoute: 'scopes',
    keyField: 'id',
    multiple: false,
    required: true,
    filterAttribute: 'scope_id',
    searchFields: ['number'],
    searchOperator: 'contains',
    textDisplayFields: ['number', firstFlexibleColumn],
    title: I18n.t('scopes.description'),
    query: getScopeFilterQuery(scopeForDuplicate, mode)
  }

  const getDuplicateServicesButtons = () => {
    if (mode === 'scope') {
      return [
        {
          onClick: () => setFilters([requestFilter, scopeFilter]),
          title: I18n.t('scopes.duplicate_services.other_request'),
          subtitle: I18n.t('scopes.duplicate_services.other_request_description'),
          icon: () => <MdControlPoint />
        },
        {
          onClick: () =>
            setFilters([
              {
                ...scopeFilter,
                query: {
                  where: {
                    eav_template_id: scopeForDuplicate.eav_template_id,
                    request_id: scopeForDuplicate.request_id
                  },
                  not: { id: scopeForDuplicate.id }
                }
              }
            ]),
          title: I18n.t('scopes.duplicate_services.same_request'),
          subtitle: I18n.t('scopes.duplicate_services.same_request_description'),
          icon: () => <MdControlPoint />
        }
      ]
    }

    const totalUniqueRequests = getUniqueRequestIds(scopeForDuplicate, 'services').size
    const buttons = [
      {
        onClick: () => setFilters([requestFilter, scopeFilter]),
        title: I18n.t('scopes.duplicate_services.other_request'),
        subtitle: I18n.t('scopes.duplicate_services.other_request_description'),
        icon: () => <MdControlPoint />,
        showButton: true
      },
      {
        onClick: () =>
          setFilters([
            {
              ...scopeFilter,
              query: {
                where: {
                  eav_template_id: scopeForDuplicate.eav_template_id,
                  request_id: scopeForDuplicate?.request_ids
                },
                not: { id: scopeForDuplicate?.parent_scope_ids }
              }
            }
          ]),
        title: I18n.t('scopes.duplicate_services.same_request'),
        subtitle: I18n.t('scopes.duplicate_services.same_request_description'),
        icon: () => <MdControlPoint />,
        showButton: totalUniqueRequests < 2
      }
    ]

    return buttons.filter((btn) => btn.showButton)
  }

  const duplicateServicesChooseModal = scopeForDuplicate && (
    <ChooseFormModal
      onClose={() => setScopeForDuplicate(null)}
      opts={{
        buttons: getDuplicateServicesButtons(),
        title: I18n.t('scopes.duplicate_services.title')
      }}
    />
  )

  const dispatchDuplicateServices = (values) => {
    dispatch(BusEvents.SHOW_LOADING_DIALOG)
    const params = getServicesParams(scopeForDuplicate, values, mode)
    const resource = mode === 'scope' ? 'scopes' : 'estimate_services'

    fetch(resource, params, {
      onSuccess: () => {
        setFilters([])
        setScopeForDuplicate(null)
        notifySuccess(I18n.t('scopes.duplicate_services.success_notification'))
        dispatch(BusEvents.HIDE_DIALOG)
        dispatch(BusEvents.RELOAD_GRID)
      },
      onError: () => {
        dispatch(BusEvents.HIDE_DIALOG)
      }
    })
  }
  const duplicateServicesFilterSteps = filters.length !== 0 && (
    <FilterStepsModal
      filters={filters}
      onClose={() => {
        setScopeForDuplicate(null)
        setFilters([])
      }}
      onDone={dispatchDuplicateServices}
      skipEmpty={false}
    />
  )

  const renderDuplicateServices = () => (
    <React.Fragment>
      {duplicateServicesChooseModal}
      {duplicateServicesFilterSteps}
    </React.Fragment>
  )

  return [renderDuplicateServices, setScopeForDuplicate]
}
