/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MdThumbUp } from 'react-icons/md'
import I18n from '/src/utils/translations'
import OrderModel from '/src/models/order'
import useFormModal from '/src/ui/core/popups/form_modal'
import useAuthorizeRequestPopupFields from '/src/ui/domain/requests/authorization_request_popup_fields'
import useFetch from '/src/hooks/api/fetch'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'

export default function AuthorizationRequestPopup({ dataItem }) {
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true)
  const [data, setData, sections] = useAuthorizeRequestPopupFields()
  const formTitle = I18n.t('requests.authorize_form.title')
  const formSubtitle = I18n.t('requests.authorize_form.subtitle')
  const modalTitle = I18n.t('requests.authorize_form.modal_title')

  const { fetch } = useFetch()

  const model = new OrderModel()
  model.columns = undefined

  const buttons = [
    {
      id: 1,
      text: I18n.t('actions.cancel'),
      cancel: true,
      class: 'cancel-button'
    },
    {
      id: 2,
      text: I18n.t('actions.approve'),
      submit: true,
      class: 'action-button',
      disabled: submitBtnDisabled,
      icon: <MdThumbUp />
    }
  ]

  const authorizeOrder = () => {
    const params = {
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: dataItem.id,
      additionalResource: { path: 'authorize' }
    }

    dispatch(BusEvents.SHOW_LOADING_DIALOG)

    fetch('orders', params, {
      useParse: true,
      onSuccess: () => {
        notifySuccess(I18n.t('requests.notification.authorization_success'))
        dispatch(BusEvents.HIDE_DIALOG)
        dispatch(BusEvents.RELOAD_GRID)
      }
    })
  }

  const modalProps = {
    modalTitle,
    formTitle,
    formSubtitle,
    label: data.request_id,
    sections,
    buttons,
    dataItem: data,
    model,
    width: 48.5,
    height: 80.5,
    sectionable: false,
    onSuccess: authorizeOrder
  }
  const [openModal, renderModal] = useFormModal(modalProps)

  useEffect(() => {
    if (Object.values(dataItem).length) setData(dataItem)
  }, [dataItem])

  useEffect(() => {
    const disableButton = !data.responsible_id || !data.order_date
    setSubmitBtnDisabled(disableButton)

    if (Object.values(data).length) openModal()
  }, [data])

  return renderModal()
}

AuthorizationRequestPopup.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

AuthorizationRequestPopup.defaultProps = {
  dataItem: {}
}
