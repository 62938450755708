// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

// eslint-disable-next-line
import { includeDateColumns } from '/src/models/concerns/datetime_columns'
import { isBlank } from '/src/utils/boolean_refinements'

export default class ScopeModel {
  constructor() {
    this.paramName = ScopeModel.paramName
    this.singularName = ScopeModel.singularName
    this.route = ScopeModel.route
  }

  static paramName = 'scope'

  static singularName = I18n.t('scopes.description')

  static route = 'scopes'

  name = I18n.t('scopes.title')

  templateType = 'scoping'

  url = 'scopes'

  flexible = true

  footer = true

  parentModels = ['request']

  gridColumnOrderOffset = 3

  initialColumns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('scopes.id'),
      orderOnForm: 1,
      hideOnForm: true,
      hideOnGrid: true,
      editable: false,
      type: 'decimal'
    },
    {
      id: 11,
      description: 'request_id',
      title: I18n.t('requests.id'),
      orderOnForm: 2,
      hideOnForm: true,
      editable: false,
      type: 'integer'
    },
    {
      id: 13,
      description: 'request.reason',
      field: 'request.reason',
      title: I18n.t('requests.reason'),
      forceUseDescription: true,
      foreignAttribute: 'request_reason',
      orderOnForm: 3,
      hideOnForm: true,
      editable: false,
      filterable: true,
      readOnly: true,
      isRetrieving: (dataItem) => isBlank(dataItem?.request?.reason),
      type: 'string'
    },
    {
      id: 14,
      description: 'request.comments',
      field: 'request.comments',
      title: I18n.t('requests.comments'),
      forceUseDescription: true,
      foreignAttribute: 'request_comments',
      orderOnForm: 4,
      hideOnForm: true,
      editable: false,
      filterable: true,
      readOnly: true,
      isRetrieving: (dataItem) => isBlank(dataItem?.request?.reason),
      type: 'string'
    },
    {
      id: 2,
      description: 'request',
      title: I18n.t('requests.id'),
      orderOnForm: 5,
      foreignKey: 'requests',
      foreignAttribute: 'request_id',
      hideOnGrid: true,
      columnSize: 'width-100',
      required: true,
      searchFields: ['id', 'reason', 'discipline][description', 'comments'],
      searchExtraQuery: {
        where: {
          '[request_statuses][i18n_id]': ['confirmed', 'in_scoping', 'clustered']
        }
      },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'discipline', 'comments'],
      textField: 'reason',
      type: 'search'
    },
    {
      id: 12,
      description: 'work_description',
      title: I18n.t('scopes.work_description'),
      hideOnGrid: true,
      orderOnForm: 6,
      allowOnEditableGrid: false,
      type: 'rich_text'
    },
    {
      id: 3,
      description: 'label',
      title: I18n.t('scopes.label'),
      orderOnForm: 7,
      hideOnForm: true,
      editable: false,
      type: 'string'
    },
    {
      id: 4,
      description: 'number',
      title: '#',
      orderOnForm: 8,
      hideOnForm: true,
      editable: false,
      width: 200,
      type: 'string'
    },
    {
      id: 6,
      description: 'team_target_hours',
      title: I18n.t('scopes.hours'),
      orderOnForm: 9,
      editable: false,
      filterable: false,
      width: 200,
      hideOnForm: true,
      type: 'numeric',
      footer: true
    },
    {
      id: 7,
      description: 'quantity',
      title: I18n.t('scopes.quantity'),
      orderOnForm: 10,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 8,
      description: 'crews',
      title: I18n.t('scopes.crews'),
      orderOnForm: 11,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 9,
      description: 'crew_size',
      title: I18n.t('scopes.crew_size'),
      orderOnForm: 12,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 10,
      description: 'working_hours',
      title: I18n.t('scopes.working_hours'),
      orderOnForm: 13,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    }
  ]

  columns = includeDateColumns(this.initialColumns)
}
