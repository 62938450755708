import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import RequestModel from '/src/models/request'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import RequestDetailSummary from '/src/ui/domain/requests/request_detail_summary'
import SubRequestListHeader from '/src/ui/domain/sub_requests/sub_request_list_header'
import ClusterBlock from '/src/ui/core/blocks/cluster_block'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import GeneralBlock from '/src/ui/core/blocks/general_block'
import SidePanelTabs from '/src/ui/core/grid/side_panel/side_panel_tabs'
import { getInfoTab, getInspectionsTab } from '/src/ui/core/tabs/get_tabs'
import ScheduleSidePanelBlock from '/src/ui/domain/requests/schedule_side_panel_block'
import TimelineSidePanelBlock from '/src/ui/domain/requests/timeline_side_panel_block'
import '/src/static/css/core/grid/grid.css'

export default function RequestsPageSidePanel({ dataItem, sections, onClose, onBlocksBuild, sidePanelMenuItems }) {
  const requestModel = new RequestModel()
  const [subRequests, setSubRequests] = useState([])

  const { fetch } = useFetch()

  const showButtons = Boolean(sidePanelMenuItems)

  const buildScheduleBlock = () => {
    return {
      component: <ScheduleSidePanelBlock dataItem={dataItem} />,
      title: I18n.t('requests.schedule_form.schedule'),
      type: 'schedule'
    }
  }

  const buildTimelineBlock = () => {
    return {
      component: <TimelineSidePanelBlock request={dataItem} />,
      title: I18n.t('timeline.title'),
      type: 'general',
      collapsible: true
    }
  }

  const buildSummary = () => {
    return {
      component: (
        <RequestDetailSummary dataItem={dataItem} requestModel={requestModel} sidePanelMenuItems={sidePanelMenuItems} />
      ),
      title: '',
      type: 'track'
    }
  }

  const buildSubRequests = () => {
    return {
      component: (
        <ClusterBlock
          dataItems={subRequests}
          dataKey="id"
          link={{
            url: '/requests',
            params: [{ fromDataItem: true, column: 'request_id', value: 'id' }]
          }}
          onSubrequestClick={onClose}
        />
      ),
      maxHeight: '420px',
      title: <SubRequestListHeader subRequests={subRequests} />,
      type: 'cluster'
    }
  }

  const buildGeneral = () => {
    return {
      component: <GeneralBlock model={requestModel} dataItem={dataItem} />,
      title: I18n.t('form.general_information'),
      type: 'general'
    }
  }

  const getBlocks = (blocks) => {
    if (subRequests.length > 0)
      return [
        buildSummary(),
        buildTimelineBlock(),
        buildSubRequests(),
        buildGeneral(),
        buildScheduleBlock(),
        ...blocks
      ]

    return [buildSummary(), buildTimelineBlock(), buildGeneral(), buildScheduleBlock(), ...blocks]
  }

  useEffect(() => {
    if (!dataItem) return

    const params =  { query: { where: { parent_id: dataItem.id } } }

    fetch('requests', params, {
      useParse: true,
      onSuccess: ({ data }) => setSubRequests(data)
    })
  }, [dataItem])

  useEffect(() => {
    if (!subRequests || !dataItem) return

    onBlocksBuild()
  }, [subRequests])

  const initialSectionBlocks = useSectionBlock({ sections, dataItem, openModal: showButtons })
  const infoBlocks = getBlocks(initialSectionBlocks)

  const infoTab = getInfoTab(infoBlocks, showButtons ? 'info' : 'preview_details')
  const inspectionsTab = getInspectionsTab({ ...dataItem, request_id: dataItem.id }, 'Request', showButtons)

  const requestsTabs = [infoTab, inspectionsTab]

  return (
    dataItem && (
      <BlocksSidePanel
        title={I18n.t('requests.request_detail.side_panel_title')}
        closable={showButtons}
        onClose={onClose}
      >
        <SidePanelTabs tabs={requestsTabs} />
      </BlocksSidePanel>
    )
  )
}

RequestsPageSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func,
  onBlocksBuild: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
}

RequestsPageSidePanel.defaultProps = {
  dataItem: null,
  onClose: () => {},
  onBlocksBuild: () => {},
  sections: []
}
