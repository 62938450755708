import { useHistory } from 'react-router-dom'
import {
  editModalMenuItem,
  deleteMenuItem,
  duplicateModalMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'

export default function useDatasheetContextMenu(model, showConfirmation) {
  const history = useHistory()

  const sidePanelMenuItems = {
    edit: editModalMenuItem(
      () => true,
      (_, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: model.paramName,
          payload: { dataItem, formType: 'edit' }
        })
      }
    ),
    duplicate: duplicateModalMenuItem(
      () => true,
      (_, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: model.paramName,
          payload: { dataItem, formType: 'duplicate' }
        })
      }
    ),
    remove: deleteMenuItem(history, model, showConfirmation)
  }
  
  const contextMenuItems = Object.values(sidePanelMenuItems)

  return [contextMenuItems, sidePanelMenuItems]
}
