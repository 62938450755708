import React from 'react'
import { MdEdit } from 'react-icons/md'
import RequestStatusLabel from '/src/ui/domain/requests/request_status_label.jsx'
import { withRouter, useHistory } from 'react-router-dom'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import QRCode from 'qrcode.react'
import Button from '/src/ui/core/buttons/button'
import PopupButton from '/src/ui/core/buttons/popup_button'
import '/src/static/css/core/blocks/request_detail_summary.css'
import '/src/static/css/core/blocks/track_block.css'

export default withRouter(function RequestDetailSummary({ dataItem, requestModel, sidePanelMenuItems }) {
  const history = useHistory()
  const requestStatus = dataItem.request_status.i18n_id
  const nonEditableStatuses = ['canceled', 'deleting', 'done']
  const isEditable = !nonEditableStatuses.includes(requestStatus)

  const hideButtons = !Boolean(sidePanelMenuItems)

  const sidePanelMenu = sidePanelMenuItems?.map((menuItem) => ({ ...menuItem, dataItem }))

  return (
    <div className="detail-summary">
      <div className="request-main-info">
        <div>
          <div className="title-side-panel">
            DPMSID
            <span className="summary-request-id">#{dataItem.id}</span>
          </div>
          <div className="detail-row">
            <RequestStatusLabel dataItem={dataItem} />
          </div>
        </div>
        <QRCode value={dataItem.id.toString()} size={76} />
      </div>
      <div className="detail-field" data-testid="detail-reference-title">
        <span>{I18n.t('requests.reason')}</span>
        <p>{dataItem.reason}</p>
        <span>{I18n.t('requests.comments')}</span>
        <p>{dataItem.comments}</p>
      </div>
      {history && !hideButtons && (
        <div className="actions-row">
          {sidePanelMenu.filter((menuItem) => menuItem.visible(dataItem)).length > 0 && (
            <div className={`track-block-buttons${!isEditable ? ' show-button-expanded' : ''}`}>
              {isEditable && (
                <Button
                  text={I18n.t('requests.request_detail.edit')}
                  icon={<MdEdit />}
                  className="edit-button-track-block"
                  onClick={() => {
                    dispatch({
                      type: BusEvents.OPEN_GRID_FORM_MODAL,
                      modelName: requestModel.paramName,
                      payload: { dataItem, formType: 'edit' }
                    })
                  }}
                  role="edit-button-panel"
                />
              )}
              <PopupButton items={sidePanelMenu} text={I18n.t('requests.request_detail.show')} role="show-button" />
            </div>
          )}
        </div>
      )}
    </div>
  )
})
