import React, { useCallback, useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import useFetch from '/src/hooks/api/fetch'
import { getDaysCounter } from '/src/utils/project_formatter'
import {
  formatDisciplineCheck,
  formatEstimates,
  formatOrders,
  formatRecycling
} from '/src/ui/domain/requests/timeline_data_formatting'

export default function useTimelineBlockUtils({ request, setShowLoading, project }) {
  const { fetch } = useFetch()
  const [estimateStatuses] = useStore('estimate_statuses')

  const [localRequestId, setLocalRequestId] = useState()
  const [timelineData, setTimelineData] = useState([])
  const [requestOrder, setRequestOrder] = useState()
  const sortTimelineByDate = useCallback((timelineMap) => {
    return timelineMap
      .sort((timelineItemA, timelineItemB) => {
        if (!timelineItemA.statusDate && !timelineItemB.statusDate) return 0
        if (!timelineItemA.statusDate) return 1
        if (!timelineItemB.statusDate) return -1
        return new Date(timelineItemA.statusDate) - new Date(timelineItemB.statusDate)
      })
      .reverse()
  }, [])

  const fetchItemsForTimeline = useCallback(
    (requestRoute) => {
      setShowLoading(true)
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: {
          where: { request_id: request.id }
        }
      }

      fetch(requestRoute, params, {
        onSuccess: ({ data }) => {
          if (!data) return
          const formattedData = []
          if (requestRoute === 'orders') setRequestOrder(formatOrders(data.data[0]))
          if (requestRoute === 'estimates') {
            setShowLoading(false)
            formattedData.push(formatEstimates(data.data[0], requestOrder, project, estimateStatuses))
          }
          data.data.forEach((fetchedItem) => {
            if (requestRoute === 'recycles') formattedData.push(formatRecycling(fetchedItem))
            if (requestRoute === 'discipline_checks') formattedData.push(formatDisciplineCheck(fetchedItem))
          })

          if (timelineData) setTimelineData((previousTimelineData) => previousTimelineData.concat(formattedData))
        }
      })
    },
    [timelineData, requestOrder, estimateStatuses]
  )

  const createdEntry = {
    statusDate: request.created_at,
    daysAgo: getDaysCounter(request.created_at),
    comments: request.comments,
    title: I18n.t('timeline.created'),
    statusColor: 'register-gray'
  }

  if (localRequestId !== request.id) {
    setTimelineData([createdEntry])
    setLocalRequestId(request.id)
    setRequestOrder()
  }

  useEffect(() => {
    if (!estimateStatuses) return
    if (requestOrder === undefined) {
      fetchItemsForTimeline('recycles')
      fetchItemsForTimeline('discipline_checks')
      fetchItemsForTimeline('orders')
    } else fetchItemsForTimeline('estimates')
  }, [localRequestId, estimateStatuses?.length, requestOrder])

  if (timelineData) return sortTimelineByDate(timelineData)

  return []
}
