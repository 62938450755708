import React, { useState } from 'react'
import I18n from '/src/utils/translations'
import { MdChecklist } from 'react-icons/md'
import PropTypes from 'prop-types'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import InspectionModel from '/src/models/inspection'

export default function InspectionsIcon({ modelName, injectedData }) {
  const [selectedRows, setSelectedRows] = useState([])

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ triggeredModelName, payload }) => {
      if (triggeredModelName === modelName || triggeredModelName === 'ALL_MODELS') {
        setSelectedRows(payload.map((payloadItem) => ({ ...payloadItem, ...injectedData })))
      }
    },
    [modelName, setSelectedRows]
  )

  const handleBatchInspectionClick = () => {
    dispatch({
      type: BusEvents.OPEN_MODAL,
      triggeredModelName: InspectionModel.paramName,
      payload: { dataItem: { items: selectedRows } }
    })
  }

  if (selectedRows.length <= 1) return null

  return (
    <CustomTooltip position="bottom" parentTitle>
      <button
        id="inspections-btn"
        type="button"
        title={I18n.t(`actions.create_inspection`)}
        data-testid="inspections-btn"
        className="btn-icon grid-header-icon"
        onClick={handleBatchInspectionClick}
      >
        <MdChecklist />
      </button>
    </CustomTooltip>
  )
}

InspectionsIcon.propTypes = {
  modelName: PropTypes.string,
  injectedData: PropTypes.oneOfType([PropTypes.object])
}

InspectionsIcon.defaultProps = {
  modelName: '',
  injectedData: {}
}
