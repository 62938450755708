import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { HiPlusCircle } from 'react-icons/hi'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useUpdateScaffoldingFormModel from '/src/hooks/update_scaffolding_form_model'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import Form from '/src/ui/core/forms/form'
import I18n from '/src/utils/translations'
import ScaffoldingModel from '/src/models/scaffolding'
import ScaffoldingPartModel from '/src/models/scaffolding_part'
import useModel from '/src/ui/core/forms/model_hook'

const scafModel = new ScaffoldingModel()

export default function ScaffoldingForm({ dataItem, type }) {
  const [globalSubproject] = useStore('subproject')
  const [scaffolding, setScaffolding] = useState(dataItem)
  const [scaffoldingModel] = useUpdateScaffoldingFormModel(scafModel, scaffolding)
  const [scafPartModel] = useModel(new ScaffoldingPartModel())
  const [onSuccess, setOnSuccess] = useState(undefined)

  const includeOnForm = () => {
    return {
      subproject_id: globalSubproject.id
    }
  }

  const onChangeColumn = (id, value) => {
    if (scaffolding && scaffolding[id] === value) return

    setScaffolding((prevScaffolding) => ({
      ...prevScaffolding,
      [id]: value
    }))
  }

  const formType = type === 'duplicate' ? 'new' : type

  const onCreatePart = () => {
    setOnSuccess(() => (data) => {
      dispatch({
        type: BusEvents.OPEN_GRID_FORM_MODAL,
        modelName: scafPartModel.paramName,
        payload: { dataItem: null, opts: { scaffolding: data } }
      })
    })

    dispatch(BusEvents.FORM_SUBMIT)
  }

  return (
    <FormWrapper
      key={dataItem?.id || 'create-scaffolding-form'}
      model={scaffoldingModel}
      dataItem={dataItem}
      type={type}
      label={type == 'edit' ? dataItem.tag_number : undefined}
      backText={I18n.t('scaffoldings.back')}
      sidePanelSections={scaffoldingModel.sections}
    >
      <React.Fragment>
        <Form
          model={scaffoldingModel}
          sections={[]}
          dataItem={dataItem}
          includeOnForm={includeOnForm()}
          type={formType}
          onChangeColumn={onChangeColumn}
          onSuccess={onSuccess}
          isDuplicate={type === 'duplicate'}
        />
        <FormButtons
          model={scaffoldingModel}
          extraButtons={[
            {
              id: 1,
              title: I18n.t('scaffoldings.buttons_form.create_part'),
              className: 'button-action-save-new',
              icon: <HiPlusCircle />,
              onClick: onCreatePart
            }
          ]}
          awaitFormSuccess
        />
      </React.Fragment>
    </FormWrapper>
  )
}

ScaffoldingForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string
}

ScaffoldingForm.defaultProps = {
  dataItem: null
}
