import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useFetch from '/src/hooks/api/fetch'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import FeedListBlock from '/src/ui/domain/blocks/feed_list_block'
import EmptyState from '/src/ui/core/layouts/empty_state'
import I18n from '/src/utils/translations'
import '/src/static/css/domain/scaffoldings/actions_feed.css'

const queryParams = (scaffoldingPartId) => ({
  requestAction: 'READ',
  httpAction: 'get',
  query: {
    where: { scaffolding_part_id: scaffoldingPartId },
    order: { date: 'desc' }
  }
})

const fillTotalDimension = (actions, scaffoldingPart) =>
  actions.map((action) => {
    return {
      ...action,
      scaffolding_type: { dimension_type: scaffoldingPart.scaffolding_type.dimension_type }
    }
  })

export default function ScaffoldingPartActionsFeed({ scaffoldingPart, parentDataItem }) {
  const { fetch } = useFetch()
  const [showLoading, setShowLoading] = useState(true)
  const [actions, setActions] = useState(true)

  useEffect(() => {
    if (!scaffoldingPart || !scaffoldingPart.id) return

    setShowLoading(true)
    fetch('scaffolding_part_actions', queryParams(scaffoldingPart.id), {
      onSuccess: ({ data }) => {
        const { data: partActions } = data
        setActions(fillTotalDimension(partActions, scaffoldingPart))
        setShowLoading(false)
      }
    })
  }, [fetch, scaffoldingPart])

  return (
    <div className="timeline-block action-timeline">
      {showLoading ? (
        <ThreeDotsLoader />
      ) : (
        <FeedListBlock
          list={actions}
          component="scaffolding_part_action_block"
          parentDataItem={parentDataItem}
          emptyMessage={
            <EmptyState
              imageSrc="feed"
              modelNameLower={I18n.t('scaffolding_part_actions.title').toLowerCase()}
              message={I18n.t('scaffolding_part_actions.feed.empty')}
            />
          }
        />
      )}
    </div>
  )
}

ScaffoldingPartActionsFeed.propTypes = {
  scaffoldingPart: PropTypes.oneOfType([PropTypes.object]).isRequired,
  parentDataItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}
