import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InputTitle from '/src/ui/core/inputs/input_title'
import { isBlank } from '/src/utils/boolean_refinements'

// eslint-disable-next-line max-lines-per-function
export default function Field({ column, renderColumn, dataItem }) {
  const {
    type,
    title,
    subtitle,
    required,
    columnSize,
    dontExpand,
    fieldClass,
    information,
    description,
    elementAfter,
    elementBefore,
    read_only: readOnly,
    column_type: columnType,
    visible_on_web: visible,
    comment_allowed: commentAllowed
  } = column

  const isRequired = typeof required === 'function' ? required(dataItem) : required

  const shouldHide = visible === false && !isRequired
  const noHeightTypes = ['datasheet_filter', 'multiple_datasheet_filter']
  const isFixed = isBlank(columnType)
  const renderColumnReturn = renderColumn(column, commentAllowed)
  const [renderedColumn, flexibleColumnsControls] = Array.isArray(renderColumnReturn)
    ? renderColumnReturn
    : [renderColumnReturn, undefined]

  const sectionClasses = classNames({
    'section-field': true,
    'dont-grow': dontExpand,
    [columnSize]: true,
    [fieldClass]: fieldClass,
    invisible: shouldHide,
    hidden: shouldHide && isFixed,
    'column-height': !noHeightTypes.includes(type),
    'input-not-editable': readOnly === true
  })

  const formatter = (title, columnType) => {
    if (columnType?.description === 'percentage') return `${title} (%)`
    return title
  }

  return (
    renderedColumn !== null && (
      <React.Fragment>
        {elementBefore}
        <div className={sectionClasses}>
          <InputTitle
            title={title}
            htmlFor={description}
            required={isRequired}
            formattedTitle={formatter(title, columnType)}
            information={information}
            commentAllowed={commentAllowed}
            flexibleColumnsControls={flexibleColumnsControls}
          >
            {subtitle && <div className="column-label-subtitle">{subtitle}</div>}
          </InputTitle>
          {renderedColumn}
        </div>
        {elementAfter}
      </React.Fragment>
    )
  )
}

Field.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    title: PropTypes.string,
    information: PropTypes.string,
    subtitle: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    dontExpand: PropTypes.bool,
    columnSize: PropTypes.string,
    type: PropTypes.string,
    fieldClass: PropTypes.string,
    elementBefore: PropTypes.element,
    elementAfter: PropTypes.element,
    read_only: PropTypes.bool,
    column_type: PropTypes.shape({ description: PropTypes.string }),
    visible_on_web: PropTypes.bool,
    comment_allowed: PropTypes.bool
  }).isRequired,
  renderColumn: PropTypes.func.isRequired,
  dataItem: PropTypes.shape({
    flexible_comments: PropTypes.oneOfType([PropTypes.object])
  })
}

Field.defaultProps = {
  dataItem: undefined
}
