import React, { useState } from 'react'
import I18n from '/src/utils/translations'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import useDuplicateServices from '/src/ui/domain/scopes/duplicate_services'
import PropTypes from 'prop-types'
import { ReactComponent as NoteStackAddSvg } from '/public/static/svg/note_stack_add'
import '/src/static/css/core/icons/popup_icon.css'

export default function DuplicateServicesIcon({ model, dataItem, scopeTemplate, eavSections }) {
  const [selectedEstimateServices, setSelectedEstimateServices] = useState([])
  const modelName = model?.paramName

  // dataItem is for the main view, we dont have dataItem in alternative view
  const eavTemplateId = dataItem?.eav_template_id ?? selectedEstimateServices[0]?.scope?.eav_template_id
  const requestIds = dataItem?.request_id
    ? [dataItem.request_id]
    : selectedEstimateServices?.map((service) => service.request_id)
  const selectedEstimateServicesIds = selectedEstimateServices?.map((service) => service.id)
  const parentScopeIds = selectedEstimateServices?.map((service) => service.scope_id)

  const [renderDuplicateServices, setEstimateServicesToDuplicate] = useDuplicateServices(
    scopeTemplate?.discipline_id,
    eavSections,
    'services'
  )

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ triggeredModelName, payload }) => {
      if (triggeredModelName === modelName || triggeredModelName === 'ALL_MODELS') {
        setSelectedEstimateServices(payload)
      }
    },
    [modelName, setSelectedEstimateServices]
  )

  if (selectedEstimateServices.length === 0) return null

  const buttonClass = 'batch-assigner-icon__button open-icon-popup btn-icon grid-header-icon'

  const handleDuplicateServices = () => {
    if (!selectedEstimateServices.length) return
    const selectedEstimatesServicesInfo = {
      eav_template_id: eavTemplateId,
      request_ids: requestIds,
      selected_estimate_services_ids: selectedEstimateServicesIds,
      parent_scope_ids: parentScopeIds
    }
    setEstimateServicesToDuplicate(selectedEstimatesServicesInfo)
  }

  return (
    <div className="note-stack-add-icon">
      <CustomTooltip position="bottom" parentTitle>
        <button
          className={buttonClass}
          title={I18n.t('dialogs.duplicate_services.title')}
          type="button"
          onClick={handleDuplicateServices}
        >
          <NoteStackAddSvg />
        </button>
      </CustomTooltip>
      {renderDuplicateServices()}
    </div>
  )
}

DuplicateServicesIcon.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  scopeTemplate: PropTypes.oneOfType([PropTypes.object]),
  eavSections: PropTypes.oneOfType([PropTypes.array])
}

DuplicateServicesIcon.defaultProps = {
  dataItem: undefined,
  scopeTemplate: undefined,
  eavSections: []
}
