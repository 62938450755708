import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MAX_PAGE_SIZE } from '/src/hooks/api/fetch_api'
import useFetch from '/src/hooks/api/fetch'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchDescription from '/src/ui/domain/eav_columns/fetch_description'
import I18n from '/src/utils/translations'
import ScopeCard from '/src/ui/domain/sub_requests/scope_card'
import '/src/static/css/domain/sub_requests/sub_request.css'
import '/src/static/css/core/buttons/buttons.css'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function SubScopesList({ subRequest, parentScopes, onDataChanged, onApiResponse, templateId }) {
  const [scopes, setScopes] = useState(subRequest.scopes)
  const [updatedScopes, setUpdatedScopes] = useState({})
  const [isDoneButtonEnabled, setIsDoneButtonEnabled] = useState(false)
  const descriptionColumn = useFetchDescription(templateId)

  const { fetch } = useFetch()

  const getParentScope = (scope) => parentScopes.find((parentScope) => parentScope.id === scope.parent_id)

  const onScopeFactorUpdated = (scopeId, factor) => {
    const newUpdatedScopes = { ...updatedScopes }
    newUpdatedScopes[scopeId] = { factor }
    setUpdatedScopes(newUpdatedScopes)
    setIsDoneButtonEnabled(true)
  }

  const updateScopesBatch = () => {
    const params = {
      httpAction: 'put',
      data: { fields: updatedScopes }
    }

    dispatch(BusEvents.SHOW_LOADING_DIALOG)
    onApiResponse('loading')

    fetch('scopes/update_in_batch', params, { useReject: true })
      .then(() => {
        onDataChanged()
        setIsDoneButtonEnabled(false)
        onApiResponse('success')
      })
      .catch(() => onApiResponse('error'))
      .finally(() => dispatch(BusEvents.HIDE_DIALOG))
  }

  const getDescription = (id) => {
    if (!scopes.length) return I18n.t('search.loading')
    return scopes.find((scope) => scope.id === id)[descriptionColumn]
  }

  useEffect(() => {
    if (scopes) return

    const params = {
      query: { where: { request_id: subRequest.id } },
      dataOptions: {
        paging: { skip: 0, pageSize: MAX_PAGE_SIZE },
        sorting: [{ field: 'number', dir: 'asc' }]
      }
    }

    fetch('scopes', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        if (data.length) setScopes(data)
      }
    })
  }, [])

  return (
    <React.Fragment>
      {scopes && (
        <React.Fragment>
          {scopes.map((scope, index) => {
            return (
              <ScopeCard
                index={index}
                key={scope.id}
                scope={scope}
                description={getDescription(scope.id)}
                label={scope.label}
                parentScope={getParentScope(scope)}
                onScopeFactorUpdated={onScopeFactorUpdated}
                editable
              />
            )
          })}
          <div className="done-action">
            <button className="done-button" disabled={!isDoneButtonEnabled} onClick={updateScopesBatch}>
              {I18n.t('actions.done')}
            </button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

SubScopesList.propTypes = {
  subRequest: PropTypes.oneOfType([PropTypes.object]).isRequired,
  parentScopes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      quantity: PropTypes.number,
      remaining_quantity: PropTypes.number,
      team_target_hours: PropTypes.number
    })
  ).isRequired,
  onDataChanged: PropTypes.func,
  onApiResponse: PropTypes.func,
  templateId: PropTypes.number
}

SubScopesList.defaultProps = {
  onDataChanged: () => { },
  onApiResponse: () => { },
  templateId: null
}
