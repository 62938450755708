import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useFetch from '/src/hooks/api/fetch'
import useFirstStringFormulaFromTemplate from '/src/hooks/first_string_formula_from_template'
import ProgressServiceModel from '/src/models/progress_service'
import EstimateServiceModel from '/src/models/estimate_service'
import { indexify } from '/src/utils/array'
import { isPresent } from '/src/utils/boolean_refinements'
import '/src/static/css/domain/inspections/inspected_services_block.css'

const progressServiceRoute = new ProgressServiceModel().complementaryRoute
const estimateServiceRoute = new EstimateServiceModel().route

const servicesRoute = {
  Progress: progressServiceRoute,
  Scope: estimateServiceRoute,
  progress_services: progressServiceRoute,
  estimate_services: estimateServiceRoute
}

export default function InspectedServicesBlock({ inspection }) {
  const { inspected_services: inspectedServices, service_ids: serviceIds } = inspection
  const [serviceItems, setServiceItems] = useState({})
  const { fetch } = useFetch()

  const inspectedTemplate = inspection?.inspected?.eav_template_id ?? inspection?.inspected_template
  const firstStringFormulaColumn = useFirstStringFormulaFromTemplate(inspectedTemplate)

  useEffect(() => {
    if (!serviceIds && !firstStringFormulaColumn) return

    const itemIds = serviceIds ?? inspectedServices.map(({ serviceable_id: id }) => id)

    const fetchServiceItems = async () => {
      const servicesParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { id: itemIds } }
      }
      const {
        data: { data: services }
      } = await fetch(servicesRoute[inspection.inspected_type], servicesParams)
      setServiceItems(indexify(services, 'id'))
    }

    fetchServiceItems()
  }, [firstStringFormulaColumn, fetch, inspectedServices, inspection.inspected_type, serviceIds])

  const inspectedServicesList =
    inspectedServices ??
    serviceIds.map((id) => ({
      serviceable_id: id,
      service_number: serviceItems[id]?.number
    }))
  const isInspectedServicesAvailable = inspectedServicesList.every(({ service_number: number }) => number)

  return (
    <div className="inspected-services-block">
      {isInspectedServicesAvailable &&
        inspectedServicesList?.map(({ service_number: number, serviceable_id: id }) => (
          <div key={number}>
            {serviceItems[id] &&
            firstStringFormulaColumn &&
            isPresent(serviceItems[id][firstStringFormulaColumn?.description])
              ? `#${number} - ${serviceItems[id][firstStringFormulaColumn?.description]}`
              : `#${number}`}
          </div>
        ))}
    </div>
  )
}

InspectedServicesBlock.propTypes = {
  inspection: PropTypes.shape({
    inspected: PropTypes.shape({
      eav_template_id: PropTypes.number
    }),
    inspected_services: PropTypes.arrayOf(
      PropTypes.shape({
        service_number: PropTypes.number
      })
    ),
    inspected_type: PropTypes.string,
    service_ids: PropTypes.arrayOf(PropTypes.number),
    inspected_template: PropTypes.number
  }).isRequired
}
