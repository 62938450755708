// eslint-disable-next-line
import { store } from 'react-context-hook'
import { includeDateColumns } from '/src/models/concerns/datetime_columns'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { normalizeDateToString } from '/src/utils/project_formatter'

export default class ScaffoldingModel {
  constructor() {
    this.paramName = ScaffoldingModel.paramName
  }

  static paramName = 'scaffolding'

  name = I18n.t('scaffoldings.title')

  singularName = I18n.t('scaffoldings.singular')

  templateType = 'scaffolding'

  route = 'scaffoldings'

  additionalGridParam = 'scaffolding_part_count=1'

  flexible = false

  templateId = -1

  cannotEditStatuses = ['dismantled']

  inspectionAllowedStatuses = ['ready_to_dismantle', 'erected']

  dismantleAllowedStatus = ['ready_to_dismantle', 'erected']

  gridColumnOrderOffset = 2

  initialColumns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('scaffoldings.id'),
      hideOnForm: true,
      hideOnGrid: true,
      hideOnSidePanel: true,
      width: 120,
      type: 'integer',
      section: 1,
      visible_on_web: true
    },
    {
      id: 2,
      description: 'tag_number',
      title: I18n.t('scaffoldings.tag_number'),
      readOnly: true,
      read_only: true,
      hideOnSidePanel: true,
      editable: false,
      width: 150,
      type: 'integer',
      hideOnForm: true,
      section: 1,
      visible_on_web: true
    },
    {
      id: 3,
      description: 'scaffolding_status',
      title: I18n.t('scaffoldings.scaffolding_status_id'),
      width: 150,
      hideOnSidePanel: true,
      hideOnForm: true,
      editable: false,
      read_only: true,
      default: function fn(_) {
        const statuses = store.get('scaffolding_statuses') || {}
        const readyToErect = Object.values(statuses)[0] || {}
        return readyToErect.id
      }.bind(this),
      fieldFormatter: function fn(dataItem) {
        const status = dataItem.scaffolding_status
        const translationKey = status && status.i18n_id
        if (!translationKey) return ''
        return I18n.t(`scaffoldings.statuses.${translationKey}`)
      }.bind(this),
      foreignKey: 'scaffolding_statuses',
      foreignAttribute: 'scaffolding_status_id',
      textDisplayFields: ['description'],
      textField: 'description',
      type: 'text',
      section: 1,
      visible_on_web: true
    },
    {
      id: 4,
      description: 'total_m3',
      title: I18n.t('scaffoldings.total_m3'),
      hideOnForm: true,
      editable: false,
      width: 150,
      type: 'decimal',
      section: 1,
      visible_on_web: true,
      sidePanelColumnFactory: true
    },
    {
      id: 5,
      description: 'total_m2',
      title: I18n.t('scaffoldings.total_m2'),
      hideOnForm: true,
      editable: false,
      width: 150,
      type: 'decimal',
      section: 1,
      visible_on_web: true,
      sidePanelColumnFactory: true
    },
    {
      id: 6,
      description: 'total_m',
      title: I18n.t('scaffoldings.total_m'),
      hideOnForm: true,
      editable: false,
      width: 150,
      type: 'decimal',
      section: 1,
      visible_on_web: true,
      sidePanelColumnFactory: true
    },
    {
      id: 7,
      description: 'main_work_order_erect_id',
      title: I18n.t('scaffoldings.main_work_order_erect_id'),
      width: 200,
      required: function fn(dataItem) {
        const { scaffolding_status_id: statusId } = dataItem || {}
        const statuses = Object.values(store.get('scaffolding_statuses') || {})
        const created = statuses.find(({ i18n_id }) => i18n_id === 'created') || {}
        return created && statusId && created.id !== statusId
      }.bind(this),
      fieldFormatter: function fn(dataItem) {
        const workOrder = dataItem.main_work_order_erect || {}
        const textDisplayFields = ['reason', 'comments']
        const workOrderDisplayValue = textDisplayFields.map((field) => workOrder[field]).filter((value) => value)
        return workOrderDisplayValue.join(' - ')
      }.bind(this),
      foreignKey: 'requests',
      foreignAttribute: 'main_work_order_erect_id',
      searchFields: ['id', 'reason', 'discipline][description', 'comments'],
      searchExtraQuery: {
        where: {
          '[request_statuses][i18n_id]': ['in_planning', 'in_performance']
        },
        order: {
          id: 'desc'
        }
      },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'discipline', 'comments'],
      textField: 'reason',
      type: 'search',
      gridFilterTable: 'main_work_order_erect',
      gridFilterType: 'contains_or',
      gridFilterFields: ['reason', 'comments'],
      filterOperators: ['contains'],
      web_form_width: '75',
      section: 1,
      visible_on_web: true,
      orderOnForm: 1
    },
    {
      id: 30,
      description: 'inspection_control',
      title: I18n.t('scaffoldings.inspection_control'),
      filterable: false,
      sortable: false,
      hideOnForm: true,
      readOnly: true,
      width: 150,
      type: 'string',
      section: 1,
      visible_on_web: true,
      sidePanelColumnFactory: true
    },
    {
      id: 8,
      description: 'erect_compensation_format',
      title: I18n.t('scaffoldings.erect_compensation_format'),
      hideOnGrid: true,
      hideOnForm: true,
      hideOnSidePanel: true,
      width: 150,
      type: 'string',
      section: 1,
      visible_on_web: true
    },
    {
      id: 9,
      description: 'erect_date',
      title: I18n.t('scaffoldings.erect_date'),
      required: true,
      default: normalizeDateToString(new Date()),
      width: 150,
      type: 'date',
      web_form_width: '25',
      section: 1,
      orderOnForm: 2
    },
    {
      id: 10,
      description: 'main_work_order_dismantle_id',
      title: I18n.t('scaffoldings.main_work_order_dismantle_id'),
      width: 200,
      required: function fn(dataItem) {
        const { scaffolding_status_id: statusId } = dataItem || {}
        const statuses = Object.values(store.get('scaffolding_statuses') || {})
        const dismantled = statuses.find(({ i18n_id }) => i18n_id === 'dismantled') || {}
        return dismantled && statusId && dismantled.id === statusId
      }.bind(this),
      fieldFormatter: function fn(dataItem) {
        const workOrder = dataItem.main_work_order_dismantle || {}
        const textDisplayFields = ['reason', 'comments']
        const workOrderDisplayValue = textDisplayFields.map((field) => workOrder[field]).filter((value) => value)
        return workOrderDisplayValue.join(' - ')
      }.bind(this),
      foreignKey: 'requests',
      foreignAttribute: 'main_work_order_dismantle_id',
      searchFields: ['id', 'reason', 'discipline][description', 'comments'],
      searchExtraQuery: {
        where: {
          '[request_statuses][i18n_id]': ['in_planning', 'in_performance']
        },
        order: {
          id: 'desc'
        }
      },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'discipline', 'comments'],
      textField: 'reason',
      type: 'search',
      gridFilterTable: 'main_work_order_dismantle',
      gridFilterType: 'contains_or',
      gridFilterFields: ['reason', 'comments'],
      filterOperators: ['contains'],
      web_form_width: '75',
      section: 1,
      visible_on_web: true,
      hideOnForm: function fn(dataItem) {
        return dataItem && (dataItem.formType === 'new' || !dataItem.id)
      },
      orderOnForm: 3
    },
    {
      id: 11,
      description: 'dismantle_date',
      title: I18n.t('scaffoldings.dismantle_date'),
      width: 150,
      type: 'date',
      web_form_width: '25',
      section: 1,
      visible_on_web: true,
      hideOnForm: function fn(dataItem) {
        return dataItem && (dataItem.formType === 'new' || !dataItem.id)
      },
      orderOnForm: 4
    },
    {
      id: 12,
      description: 'dismantle_compensation_format',
      title: I18n.t('scaffoldings.dismantle_compensation_format'),
      hideOnGrid: true,
      hideOnForm: true,
      hideOnSidePanel: true,
      width: 150,
      type: 'string',
      section: 1,
      visible_on_web: true
    },
    {
      id: 13,
      description: 'operation_erect',
      title: I18n.t('scaffoldings.operation_erect'),
      width: 150,
      type: 'string',
      web_form_width: '50',
      section: 1,
      visible_on_web: true,
      orderOnForm: 5
    },
    {
      id: 14,
      description: 'operation_dismantle',
      title: I18n.t('scaffoldings.operation_dismantle'),
      width: 150,
      type: 'string',
      web_form_width: '50',
      section: 1,
      visible_on_web: true,
      orderOnForm: 6
    },
    {
      id: 15,
      description: 'qr_code',
      title: I18n.t('scaffoldings.qr_code'),
      width: 150,
      type: 'string',
      web_form_width: '25',
      section: 1,
      visible_on_web: true,
      orderOnForm: 7
    },
    {
      id: 16,
      description: 'number_of_tags',
      title: I18n.t('scaffoldings.number_of_tags'),
      width: 150,
      type: 'integer',
      metadata: JSON.stringify({ min_value: 0 }),
      web_form_width: '25',
      section: 1,
      visible_on_web: true,
      orderOnForm: 8
    },
    {
      id: 17,
      description: 'tag_removed',
      title: I18n.t('scaffoldings.tag_removed'),
      width: 150,
      type: 'boolean',
      web_form_width: '50',
      section: 1,
      visible_on_web: true,
      orderOnForm: 9
    },
    {
      id: 18,
      description: 'description',
      title: I18n.t('scaffoldings.description'),
      required: true,
      type: 'string',
      web_form_width: '66',
      section: 2,
      visible_on_web: true,
      orderOnForm: 10
    },
    {
      id: 19,
      description: 'project_number',
      title: I18n.t('scaffoldings.project_number'),
      width: 150,
      type: 'string',
      web_form_width: '33',
      section: 2,
      visible_on_web: true,
      orderOnForm: 11
    },
    {
      id: 20,
      description: 'user_of_scaffolding',
      title: I18n.t('scaffoldings.user_of_scaffolding'),
      required: true,
      type: 'string',
      web_form_width: '33',
      section: 2,
      visible_on_web: true,
      orderOnForm: 12
    },
    {
      id: 21,
      title: I18n.t('scaffoldings.area_id'),
      description: 'area',
      forceUseDescription: true,
      field: 'area.description',
      validation: {
        required: true
      },
      foreignKey: 'areas',
      foreignAttribute: 'area_id',
      filterable: true,
      required: true,
      type: 'drop',
      web_form_width: '33',
      section: 2,
      visible_on_web: true,
      orderOnForm: 13
    },
    {
      id: 22,
      title: I18n.t('scaffoldings.subarea_id'),
      description: 'subarea',
      forceUseDescription: true,
      field: 'subarea.description',
      validation: {
        required: true
      },
      foreignKey: 'subareas',
      foreignAttribute: 'subarea_id',
      filterable: true,
      required: true,
      type: 'cascade_drop',
      dependency: 'area',
      web_form_width: '33',
      section: 2,
      visible_on_web: true,
      orderOnForm: 14
    },
    {
      id: 23,
      description: 'area_noise_id',
      title: I18n.t('scaffoldings.area_noise_id'),
      required: true,
      default: 1,
      width: 150,
      fieldFormatter: function fn(field) {
        const areaNoise = field.area_noise || field
        const translationKey = areaNoise && areaNoise.i18n_id
        if (!translationKey) return ''
        return I18n.t(`scaffoldings.area_noises.${translationKey}`)
      }.bind(this),
      foreignKey: 'area_noises',
      foreignAttribute: 'area_noise_id',
      type: 'drop',
      web_form_width: '33',
      section: 2,
      visible_on_web: true,
      orderOnForm: 15
    },
    {
      id: 24,
      description: 'load_class_id',
      title: I18n.t('scaffoldings.load_class_id'),
      required: true,
      default: 3,
      width: 150,
      fieldFormatter: function fn(field) {
        const loadClass = field.load_class || field
        const translationKey = loadClass && loadClass.i18n_id
        if (!translationKey) return ''
        return I18n.t(`scaffoldings.load_classes.${translationKey}`)
      }.bind(this),
      foreignKey: 'load_classes',
      foreignAttribute: 'load_class_id',
      type: 'drop',
      web_form_width: '33',
      section: 2,
      visible_on_web: true,
      orderOnForm: 16
    },
    {
      id: 25,
      description: 'wind_exposed',
      title: I18n.t('scaffoldings.wind_exposed'),
      width: 150,
      type: 'boolean',
      web_form_width: '33',
      section: 2,
      visible_on_web: true,
      orderOnForm: 17
    },
    {
      id: 26,
      description: 'comment',
      title: I18n.t('scaffoldings.comment'),
      type: 'multiline_text',
      web_form_width: '100',
      maxLength: 255,
      section: 2,
      visible_on_web: true,
      orderOnForm: 18
    },
    {
      id: 27,
      description: 'erect_with_deviation',
      title: I18n.t('scaffoldings.erect_with_deviation'),
      width: 150,
      type: 'boolean',
      web_form_width: '25',
      section: 2,
      visible_on_web: true,
      orderOnForm: 19
    },
    {
      id: 28,
      description: 'deviation_improved',
      title: I18n.t('scaffoldings.deviation_improved'),
      width: 150,
      hideOnForm: function fn(dataItem) {
        return !dataItem || !(dataItem?.erect_with_deviation === true)
      },
      visible_on_web: true,
      type: 'date',
      web_form_width: '25',
      section: 3,
      orderOnForm: 20
    },
    {
      id: 29,
      description: 'description_of_deviation',
      title: I18n.t('scaffoldings.description_of_deviation'),
      width: 150,
      hideOnForm: function fn(dataItem) {
        return !dataItem || !(dataItem?.erect_with_deviation === true)
      },
      visible_on_web: true,
      type: 'string',
      web_form_width: '75',
      section: 3,
      orderOnForm: 21
    },
    {
      id: 31,
      description: 'pictures',
      title: I18n.t('scaffoldings.pictures'),
      type: 'fixed_picture',
      hideOnGrid: true,
      hideOnSidePanel: true,
      visible_on_web: true,
      orderOnForm: 1,
      web_form_width: '100',
      section: 4
    }
  ]

  columns = includeDateColumns(this.initialColumns)

  sections = [
    {
      id: 1,
      position: 1,
      title: I18n.t('scaffoldings.sections.general'),
      columns: this.columns.filter((column) => column.section === 1)
    },
    {
      id: 2,
      position: 2,
      title: I18n.t('scaffoldings.sections.details'),
      columns: this.columns.filter((column) => column.section === 2)
    },
    {
      id: 3,
      position: 3,
      title: I18n.t('scaffoldings.sections.deviations'),
      columns: this.columns.filter((column) => column.section === 3)
    },
    {
      id: 4,
      position: 4,
      title: I18n.t('scaffoldings.sections.pictures'),
      columns: this.columns.filter((column) => column.section === 4),
      hideOnInfoTab: true
    }
  ]

  galleryTabSection = [
    {
      id: 4,
      position: 4,
      title: I18n.t('scaffoldings.sections.pictures'),
      columns: [
        {
          id: 31,
          description: 'pictures',
          title: I18n.t('scaffoldings.pictures'),
          type: 'fixed_picture',
          hideOnGrid: true,
          visible_on_web: true,
          fixedPicture: true,
          orderOnForm: 1,
          web_form_width: '100',
          section: 4
        }
      ]
    }
  ]
}
