import { useState, useRef, useEffect, useCallback } from 'react'
import { averageDownloadSpeed } from '/src/utils/navigation'

export default function useManageDependencies({ fixedForm }) {
  const loadTimeout = useRef()
  const [finishedInitialLoad, setFinishedInitialLoad] = useState(fixedForm)

  const checkDependencies = useCallback(
    (
      sections,
      loadingFormulas,
      loadingDatasheetFilters,
      formulasServices,
      templateId,
      hasFormulasServices
    ) => {
      if (fixedForm) return false
      if (loadTimeout.current) clearTimeout(loadTimeout.current)
      if (
        sections === null ||
        (templateId > 0 && (loadingFormulas || loadingDatasheetFilters)) ||
        (hasFormulasServices && formulasServices === null)
      )
        return true

      const timeoutTime = averageDownloadSpeed()

      if (process.env.TEST_ENV === 'true' && !finishedInitialLoad) {
        setFinishedInitialLoad(true)
      } else {
        loadTimeout.current = setTimeout(() => {
          setFinishedInitialLoad(true)
        }, timeoutTime)
      }

      return false
    },
    [setFinishedInitialLoad]
  )

  useEffect(() => {
    return () => {
      if (loadTimeout.current) clearTimeout(loadTimeout.current)
    }
  }, [])

  return {
    checkDependencies,
    finishedInitialLoad
  }
}
