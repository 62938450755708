/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import { MdArrowForward, MdArrowBack } from 'react-icons/md'
import I18n from '/src/utils/translations'
import InputSearch from '/src/ui/core/inputs/input_search'
import RequiredMark from '/src/ui/core/alerts/required_mark'
import Modal from '/src/ui/core/popups/modal'
import { isBlank } from '/src/utils/boolean_refinements'
import { escapeCommas } from '/src/utils/string'
import { ContainsOperator } from '/src/models/concerns/filter_operators'
import { filterDataByMappedValues } from '/src/utils/filter_data_by_mapped_values'
import '/src/static/css/core/popups/filter_steps_modal.css'

// eslint-disable-next-line max-lines-per-function
export default function FilterStepsModal({
  route,
  filters,
  resultTitle,
  tag,
  currentValues,
  skipEmpty,
  onDone,
  onClose,
  skipLast
}) {
  const [values, setValues] = useState(currentValues)
  const [index, setIndex] = useState(0)
  const [stepStatus, setStepStatus] = useState('loading')
  const [direction, setDirection] = useState('forward')
  const [selectedValue, setSelectedValue] = useState(null)
  const [skipping, setSkipping] = useState(true)
  const [fetchStatus, setFetchStatus] = useState('')
  const [responseData, setResponseData] = useState([])
  const dropRef = useRef(null)

  const currentFilter = filters[index]

  const isFilterSingle = filters.length === 1
  const totalStepsWithResultTitle = isFilterSingle ? 1 : filters.length + 1
  const totalSteps = resultTitle ? totalStepsWithResultTitle : filters.length
  const isLastStep = useCallback(() => index === totalSteps - 1, [index, totalSteps])

  const updateSelectedValue = (newIndex) => {
    if (newIndex >= filters.length) return
    const filter = filters[newIndex].filterAttribute
    setSelectedValue(values[filter])
  }

  const skipStep = () => {
    const newValues = { ...values, [currentFilter.filterAttribute]: null }

    if (isFilterSingle) {
      onDone(newValues)
      return
    }

    setValues(newValues)
    setDirection('forward')

    const newIndex = Math.min(index + 1, filters.length)
    setIndex(newIndex)
    updateSelectedValue(newIndex)
  }

  const toNextStep = useCallback(
    (currentFilterValue) => {
      const currentValue = currentFilterValue || selectedValue
      const newValues = {
        ...values,
        [currentFilter.filterAttribute]: currentValue
      }

      // this conditional will check if there was a value previously selected,
      // and if that value is different from the value selected now
      if (
        currentValue !== undefined &&
        currentValues[currentFilter.filterAttribute] !== undefined &&
        currentValue !== currentValues[currentFilter.filterAttribute]
      ) {
        const resetValues = { ...newValues }

        // this for removes all values that were selected before,
        // but only the values that come "after" the one that was selected now
        for (let i = index + 1; i < totalSteps - 1; i += 1) {
          const keyToRemove = filters[i].filterAttribute
          delete resetValues[keyToRemove]
        }

        setValues(resetValues)
      } else {
        setValues(newValues)
      }

      setDirection('forward')

      const newIndex = Math.min(index + 1, filters.length)
      setIndex(newIndex)
      updateSelectedValue(newIndex)
    },
    [filters, index, selectedValue, values, currentFilter]
  )

  const toPreviousStep = useCallback(() => {
    setDirection('backward')
    const newIndex = Math.max(index - 1, 0)
    setIndex(newIndex)
    updateSelectedValue(newIndex)
  }, [index])

  const handleDoneClick = useCallback((e, autoSkipValues, autoSelectedItem) => {
    const choiceValues = autoSkipValues ?? values;
    const choiceCurrent = autoSelectedItem ?? selectedValue;

    let newValues = isFilterSingle
      ? {
          ...choiceValues,
          [currentFilter.filterAttribute]: choiceCurrent
        }
      : { ...choiceValues }

    if (!resultTitle) {
      const last = currentFilter.filterAttribute
      let selectedObj

      if (choiceCurrent && choiceCurrent[0] && responseData.length > 0) {
        if (typeof choiceCurrent[0] === 'object')
          selectedObj = responseData.find((item) => item[last] === choiceCurrent[0][last])
        else selectedObj = responseData.find((item) => item[last] === choiceCurrent[0])
      }

      newValues = { ...choiceValues, [last]: choiceCurrent, selectedObj }
    }

    onDone(newValues)
  }, [currentFilter, isFilterSingle, onDone, responseData, resultTitle, selectedValue, values])

  const onSelectedValue = useCallback(
    (_ignored, value) => {
      if (Array.isArray(value)) {
        const val = value.map((item) => {
          return {
            [currentFilter.filterAttribute]: item,
            computed_text_field: item.toString()
          }
        })
        setSelectedValue(val)
        return
      }

      if (isBlank(value)) {
        setSelectedValue(null)
        return
      }

      setSelectedValue([value])
      if (!isLastStep()) toNextStep([value])
    },
    [toNextStep, currentFilter, isLastStep]
  )

  const handleSkip = () => {
    if (skipEmpty && responseData.length === 0 && index !== totalSteps - 1) {
      direction === 'backward' ? toPreviousStep() : toNextStep()
    }
    setSkipping(false)
  }

  const onFetch = (fetchedData) => {
    setResponseData(fetchedData)
    setFetchStatus('SUCCESS')
  }

  const props = {
    className: 'selector-combobox',
    popupClassName: 'filters-steps-combobox-list',
    iconClassName: 'k-i-search',
    onChange: onSelectedValue,
    onFetch,
    ref: dropRef,
    appendTo: '#filter-steps-options',
    readOnly: false,
    opened: true
  }

  const [inputProps, setInputProps] = useState(props)

  useEffect(() => {
    if (index === filters.length) return

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: currentFilter.query || {}
    }

    // only apply filters from previous steps
    for (let i = 0; i < index; i += 1) {
      const filter = filters[i].filterAttribute

      const queryType = filters[i].queryType ?? 'whereSkip'
      if (values[filter]) {
        const value = values[filter]
        if (value[0] ?? false) {
          const isObj = typeof value[0] === 'object'
          const whereVal = isObj ? value.map((item) => item.computed_text_field) : value[0]
          const filterValue = Array.isArray(whereVal) ? whereVal : value
          params.query[queryType] = {
            ...params.query[queryType],
            [filter]: escapeCommas(filterValue)
          }
        }
      } else if (values[filter] === null && params.query[queryType]) delete params.query[queryType][filter]
    }

    if ((resultTitle || index < filters.length - 1) && (currentFilter.distinct ?? true))
      params.query.distinct = [currentFilter.filterAttribute]

    const mergedQueries = currentFilter.searchExtraQuery
      ? merge(currentFilter.searchExtraQuery, params.query)
      : params.query

    setStepStatus('loading')
    setFetchStatus('')
    let newProps = {
      ...props,
      searchRoute: currentFilter.searchRoute || route,
      multiple: !!currentFilter.multiple,
      distinct: currentFilter.distinct ?? true,
      searchFields: currentFilter.searchFields || [currentFilter.filterAttribute],
      searchOperator: currentFilter.searchOperator || ContainsOperator,
      searchExtraQuery: mergedQueries,
      textDisplayFields: currentFilter.textDisplayFields || [currentFilter.filterAttribute],
      keyField: currentFilter.keyField || currentFilter.filterAttribute,
      value: values[currentFilter.filterAttribute],
      index
    }

    setInputProps(newProps)
    setSkipping(true)
  }, [values, index, filters, currentFilter])

   const autoSkipFinalStep  = () => {
    if (isLastStep() && skipLast) {
      if (responseData.length === 1) {
        const autoSelectedItem = [responseData[0]]
        const autoSkipValues = {
          ...values,
          [currentFilter.filterAttribute]: autoSelectedItem
        }
        handleDoneClick(null, autoSkipValues, autoSelectedItem)
      }
    }
  }

  useEffect(() => {
    if (stepStatus !== 'loading' || fetchStatus !== 'SUCCESS') return
    const currentFilterAttribute = currentFilter.filterAttribute
    const existingValues = values[currentFilterAttribute]

    let foundValues
    let valueToSelect

    if (Array.isArray(existingValues)) {
      const filteredValues = filterDataByMappedValues(existingValues, responseData, currentFilter.keyField)
      foundValues = filteredValues.length === existingValues.length ? existingValues : filteredValues
      valueToSelect = foundValues.length > 0 ? foundValues : undefined
    } else if (existingValues) {
      foundValues = responseData.find((opt) => opt.computed_text_field === existingValues.toString())
      valueToSelect = foundValues ? existingValues : undefined
    }

    // this lines resets the value from the step when it is not found
    setSelectedValue(valueToSelect)

    setStepStatus('loaded')
    dropRef.current.focus()

    autoSkipFinalStep()

    if (skipping) handleSkip()
  }, [stepStatus, fetchStatus, responseData, currentFilter, skipping])

  const isDisabled = stepStatus !== 'loaded'

  const renderKey = (key) => {
    const filterToRender = Object.values(filters).find((filter) => filter.filterAttribute === key)
    return filterToRender ? filterToRender.title : key
  }

  const renderValues = (key) => {
    if (!Array.isArray(values[key])) return values[key]

    return values[key]
      .filter((v) => v || (typeof val === 'object' && v[key]))
      .map((val) => {
        return typeof val === 'object' ? val[key] : val
      })
      .join(', ')
  }

  const subtitle = () => {
    if (currentFilter?.information) return currentFilter?.information
    const isLast = index === filters.length
    const sufix = isLast ? I18n.t('filter_steps_modal.filter_result') : currentFilter.title
    return I18n.t('filter_steps_modal.subtitle', { title: sufix })
  }

  return (
    <Modal
      loading={{ status: stepStatus === 'loading', text: I18n.t('main_dialog.loading_title') }}
      height={90}
      width={50}
      title={`${I18n.t('filter_steps_modal.step')} ${index + 1}/${totalSteps}`}
      onClose={onClose}
      className="k-window-wrapper modal-wrapper"
      appendToBody
      closable
    >
      <div key={index} id="filter-steps-modal" className="filter-steps-modal">
        <div id="filter-steps-title" className="filter-steps-title">
          <RequiredMark required={currentFilter?.required ?? false} />
          {index === filters.length ? I18n.t('filter_steps_modal.filter_result') : currentFilter.title}
          {tag ? <span>{tag}</span> : <span className="count">{responseData.length}</span>}
        </div>

        <div id="filter-steps-subtitle" className="filter-steps-subtitle">
          {subtitle()}
        </div>

        <div id="filter-steps-content" className="filter-steps-content">
          {index === filters.length && !isFilterSingle ? (
            <div className="filter-result">
              <div className="result-title">
                <span>{resultTitle}</span>
                <img src="/static/svg/tick_blue.svg" alt="Blue tick" />
              </div>
              <div className="result-filters">
                {Object.keys(values)
                  .filter((key) => values[key])
                  .map((k) => (
                    <p key={k}>{`${renderKey(k)}: ${renderValues(k)}`}</p>
                  ))}
              </div>
            </div>
          ) : (
            <React.Fragment>
              {inputProps && inputProps.searchExtraQuery && index === inputProps.index && (
                <InputSearch key={index} inputProps={inputProps} />
              )}
              <div id="filter-steps-options" className="filter-steps-options" />
            </React.Fragment>
          )}
        </div>

        <div className="filter-steps-action-buttons">
          <div id="filter-steps-prev-button" className="filter-steps-button">
            {index > 0 && (
              <button
                className="form-cancel-button"
                disabled={isDisabled}
                onClick={() => toPreviousStep()}
                type="button"
              >
                <MdArrowBack />
                {` ${I18n.t('actions.prev')}`}
              </button>
            )}
          </div>

          <div id="filter-steps-next-button" className="filter-steps-button">
            {isLastStep() ? (
              <React.Fragment>
                {isFilterSingle && !currentFilter?.required && (
                  <button className="form-cancel-button" disabled={isDisabled} onClick={() => skipStep()} type="button">
                    {`${selectedValue === undefined ? I18n.t('actions.cancel') : I18n.t('actions.clear')}`}
                  </button>
                )}

                <button
                  className="next-button"
                  disabled={isDisabled || (!resultTitle && !selectedValue) || (isFilterSingle && !selectedValue)}
                  onClick={handleDoneClick}
                  type="button"
                >
                  {`${I18n.t('actions.done')}`}
                </button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!currentFilter?.required && (
                  <button className="form-cancel-button" disabled={isDisabled} onClick={() => skipStep()} type="button">
                    {`${I18n.t('actions.skip')}`}
                  </button>
                )}
                <button
                  className="next-button"
                  disabled={isDisabled || !selectedValue}
                  onClick={() => toNextStep()}
                  type="button"
                >
                  {`${I18n.t('actions.next')} `}
                  <MdArrowForward />
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

FilterStepsModal.propTypes = {
  route: PropTypes.string,
  filters: PropTypes.oneOfType([PropTypes.array]).isRequired,
  tag: PropTypes.string,
  resultTitle: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  currentValues: PropTypes.oneOfType([PropTypes.object]),
  skipEmpty: PropTypes.bool,
  onDone: PropTypes.func,
  onClose: PropTypes.func,
  skipLast: PropTypes.bool
}

FilterStepsModal.defaultProps = {
  route: undefined,
  tag: undefined,
  resultTitle: undefined,
  currentValues: {},
  skipEmpty: true,
  onDone: () => {},
  onClose: () => {},
  skipLast: false
}
