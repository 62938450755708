import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import GridHeaderIcons from '/src/ui/core/grid/grid_header_icons'
import GridHeaderCounter from '/src/ui/core/grid/grid_header_counter'
import GridHeaderPaging from '/src/ui/core/grid/grid_header_paging'
import GridPageSize from '/src/ui/core/grid/grid_page_size'
import BasicGridHeader from '/src/ui/core/grid/basic_grid_header'
import { isBlank } from '/src/utils/boolean_refinements'
import useChangePage from '/src/hooks/change_page'
import I18n from '/src/utils/translations'
import { SAVED_LABEL_TIMER, STORE_PREFERENCES_STATUS } from '/src/models/concerns/user'
import '/src/static/css/core/grid/grid.css'

export default function SimpleGridHeader({
  page,
  take,
  total,
  onPageChange,
  onChangePageSize,
  gridTitle,
  loading,
  icons,
  selectedItem,
  itemsQuantity,
  savingStatus,
  children,
  modelName,
  templateId,
  model,
}) {
  const { currentPage, jumpToPage, pageClick } = useChangePage(page, total, onPageChange, modelName, loading)
  const [savingPreferencesLabel, setSavingPreferencesLabel] = useState('')

  const savedLabelTimer = useRef()

  const activateSavingLabelTimer = useCallback(() => {
    if (savedLabelTimer.current) clearTimeout(savedLabelTimer.current)
    savedLabelTimer.current = setTimeout(() => setSavingPreferencesLabel(''), SAVED_LABEL_TIMER)
  }, [])

  useEffect(() => {
    switch (savingStatus) {
      case STORE_PREFERENCES_STATUS.SAVING:
        setSavingPreferencesLabel(I18n.t('grid.saving_preferences'))
        break
      case STORE_PREFERENCES_STATUS.SAVED:
        if (isBlank(savingPreferencesLabel)) break
        setSavingPreferencesLabel(I18n.t('grid.saved_preferences'))
        activateSavingLabelTimer()
        break
      case STORE_PREFERENCES_STATUS.FAILED:
        if (isBlank(savingPreferencesLabel)) break
        setSavingPreferencesLabel(I18n.t('grid.failed_saving_preferences'))
        activateSavingLabelTimer()
        break
      default:
        break
    }

    return () => {
      if (savedLabelTimer.current) clearTimeout(savedLabelTimer.current)
    }
  }, [savingStatus, savingPreferencesLabel, activateSavingLabelTimer])

  return (
    <BasicGridHeader gridTitle={gridTitle} itemsQuantity={itemsQuantity} labels={children}>
      <div className="entity-grid-header-icons">
        <span className="grid-header-text">{savingPreferencesLabel}</span>
        <GridHeaderIcons
          icons={icons}
          selectedItem={selectedItem}
          model={model}
          templateId={templateId}
        />
        <div className="entity-grid-pagination" data-testid="grid-options">
          <GridPageSize onChangePageSize={onChangePageSize} quantity={take} />
          <GridHeaderCounter page={currentPage} jumpToPage={jumpToPage} total={total} />
          <GridHeaderPaging onPageChange={pageClick} take={take} />
        </div>
      </div>
    </BasicGridHeader>
  )
}

SimpleGridHeader.propTypes = {
  page: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  gridTitle: PropTypes.string.isRequired,
  icons: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onChangePageSize: PropTypes.func,
  labels: PropTypes.oneOfType([PropTypes.array]),
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  itemsQuantity: PropTypes.number,
  savingStatus: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.arrayOf(PropTypes.element)]),
  modelName: PropTypes.string,
  loading: PropTypes.bool,
  templateId: PropTypes.number,
  model: PropTypes.oneOfType([PropTypes.object]),
}

SimpleGridHeader.defaultProps = {
  selectedItem: undefined,
  labels: [],
  itemsQuantity: undefined,
  savingStatus: STORE_PREFERENCES_STATUS.IDLE,
  onChangePageSize: () => {},
  children: undefined,
  modelName: '',
  loading: false,
  templateId: null,
  model: null
}
