import React from 'react'
import {
  MdFlag,
  MdCheck,
  MdClear,
  MdDelete,
  MdReplay,
  MdPanTool,
  MdThumbUp,
  MdDateRange,
  MdAssignment,
  MdCheckCircle,
  MdDescription,
  MdAttachMoney,
  MdPendingActions,
  MdAssignmentTurnedIn,
} from 'react-icons/md'
import { GiTargeted } from 'react-icons/gi'
import { IoMdSpeedometer } from 'react-icons/io'
import { FaCheckDouble } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useUpdateEffect from '/src/hooks/update_effect'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import {
  editModalMenuItem,
  duplicateModalMenuItem,
  createInspectionModalMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import {
  status,
  editPossibleStatus,
  duplicatePossibleStatus,
  schedulePossibleStatus,
  revertPossibleStatus,
  donePossibleStatus,
  cancelPossibleStatus,
  getOptionVisibility
} from '/src/utils/constants/request'
import RequestModel, {
  isRecyclableRequest,
  isDisciplineCheckPending,
  isReadyForDisciplineCheck
} from '/src/models/request'
import EstimateModel from '/src/models/estimate'
import InspectionModel from '/src/models/inspection'
import useRejectRequest from '/src/ui/domain/requests/reject_request'
import useRequestConfirmationPopup from '/src/ui/domain/requests/request_confirmation_popup'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import useRequestsPinned from '/src/hooks/requests_pinned'
import I18n from '/src/utils/translations'
import useRemoveRecycleMenuItem from '/src/ui/domain/requests/remove_recycle_menu_item'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/panels/side_panel.css'

// eslint-disable-next-line max-len, max-lines-per-function
export default function useRequestContextMenu({
  showConfirmation,
  setRecycleDataItem,
  setScheduleDataItem,
  setAuthorizeDataItem,
  openWorkPackagePopup,
  setDisciplineCheckDataItem,
}) {
  const history = useHistory()
  const { clearRequests, setPinnedRequests } = useRequestsPinned()
  const estimateModel = new EstimateModel()
  const requestModel = new RequestModel()
  const inspectionModelParamName = InspectionModel.paramName
  const getOrder = useFetchAPI('orders')
  const rejectAction = useRejectRequest(showConfirmation)
  const showActionConfirmation = useRequestConfirmationPopup(showConfirmation)
  const successBtnColor = '#607D8B'

  const removeRecycleMenuItem = useRemoveRecycleMenuItem(showActionConfirmation, successBtnColor)

  useUpdateEffect(() => {
    if (getOrder.status === 'FETCHING') return dispatch(BusEvents.SHOW_LOADING_DIALOG)

    if (getOrder.status === 'SUCCESS') {
      dispatch(BusEvents.HIDE_DIALOG)

      if (getOrder.responseData.data.length === 0) {
        notifyError(I18n.t('requests.notification.missing_order_error'))
        return true
      }

      setAuthorizeDataItem(getOrder.responseData.data[0])
    }

    return true
  }, [getOrder.status, getOrder.requestAction, getOrder.responseData])

  const deleteParams = (item) => {
    const opts = { id: item.id }
    if (status[item.request_status_id] === 'registered') return { path: 'revert_workflow', ...opts }
    return { requestAction: 'DESTROY', httpAction: 'delete', path: 'delete_sub_request', ...opts }
  }

  const deleteActionOpts = (item) => {
    const opts = {
      actionName: 'delete',
      onSuccess: () => {
        clearRequests()
      }
    }

    if (!item.parent_id) return opts
    opts.customSuccessMessage = {
      body: I18n.t('requests.notification.deleting_sub_request', { id: item.id }),
      closeTimeout: 10
    }
    return opts
  }

  const actionParams = (opts) => {
    return {
      resourcePath: 'requests',
      requestAction: opts.requestAction || 'UPDATE',
      httpAction: opts.httpAction || 'put',
      resourceId: opts.id,
      additionalResource: { path: opts.path }
    }
  }

  const contextMenuItems = [
    editModalMenuItem(
      (dataItem) => editPossibleStatus.includes(status[dataItem.request_status_id]),
      (e, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: requestModel.paramName,
          payload: { dataItem, formType: 'edit' }
        })
      }
    ),
    duplicateModalMenuItem(
      (dataItem) => duplicatePossibleStatus.includes(status[dataItem.request_status_id]),
      (e, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: requestModel.paramName,
          payload: { dataItem, formType: 'duplicate' }
        })
      }
    ),
    {
      text: I18n.t('actions.recycle'),
      icon: <MdPendingActions />,
      onClick: (e, dataItem) => setRecycleDataItem({ request_id: dataItem.id, request: dataItem }),
      visible: (dataItem) => isRecyclableRequest(dataItem, status)
    },
    removeRecycleMenuItem,
    createInspectionModalMenuItem(
      (dataItem) => status[dataItem.request_status_id] !== 'canceled',
      (e, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_MODAL,
          triggeredModelName: inspectionModelParamName,
          payload: { dataItem }
        })
      }
    ),
    {
      text: I18n.t('actions.perform_discipline_check'),
      icon: <MdAssignmentTurnedIn />,
      onClick: (e, dataItem) =>
        setDisciplineCheckDataItem({
          request_id: dataItem.id,
          request: dataItem,
          isReady: true
        }),
      visible: (dataItem) => isDisciplineCheckPending(dataItem, status)
    },
    {
      text: I18n.t('actions.ready_for_discipline'),
      icon: <MdAssignment />,
      onClick: (e, dataItem) =>
        setDisciplineCheckDataItem({
          request_id: dataItem.id,
          request: dataItem
        }),
      visible: (dataItem) => isReadyForDisciplineCheck(dataItem, status)
    },
    {
      text: I18n.t('actions.schedule'),
      icon: <MdDateRange />,
      onClick: (e, item) => setScheduleDataItem(item),
      visible: (dataItem) => schedulePossibleStatus.includes(status[dataItem.request_status_id])
    },
    {
      text: I18n.t('actions.confirm'),
      icon: <MdCheck />,
      onClick: (e, dataItem) => {
        showActionConfirmation(dataItem, actionParams({ id: dataItem.id, path: 'realize' }), {
          actionName: 'confirm',
          buttonColor: successBtnColor,
          actionText: I18n.t('requests.confirmation_modal.confirm_confirmation'),
          cancelText: I18n.t('requests.confirmation_modal.confirm_cancel')
        })
      },
      visible: (dataItem) => status[dataItem.request_status_id] === 'registered'
    },
    {
      text: I18n.t('actions.reject'),
      icon: <MdPanTool />,
      onClick: (e, dataItem) => rejectAction(dataItem),
      visible: (dataItem) => status[dataItem.request_status_id] === 'authorization_pending'
    },
    {
      text: I18n.t('actions.approve'),
      icon: <MdThumbUp />,
      onClick: (e, dataItem) => {
        const orderQueryParams = {
          requestAction: 'READ',
          httpAction: 'get',
          query: { where: { request_id: dataItem.id, 'order_statuses][description': 'pending' } }
        }
        getOrder.fetchAPI(orderQueryParams)
      },
      visible: (dataItem) => status[dataItem.request_status_id] === 'authorization_pending'
    },
    {
      text: I18n.t('actions.revert'),
      icon: <MdReplay />,
      onClick: (e, dataItem) => {
        showActionConfirmation(dataItem, actionParams({ id: dataItem.id, path: 'revert_workflow' }), {
          actionName: 'revert'
        })
      },
      visible: (dataItem) => !dataItem.parent_id && revertPossibleStatus.includes(status[dataItem.request_status_id])
    },
    {
      text: `${I18n.t('actions.delete')}`,
      icon: <MdDelete />,
      onClick: (e, dataItem) => {
        showActionConfirmation(dataItem, actionParams({ id: dataItem.id, path: 'revert_workflow' }), {
          actionName: 'delete'
        })
      },
      visible: (dataItem) => !dataItem.parent_id && status[dataItem.request_status_id] === 'canceled'
    },
    {
      text: I18n.t('actions.done'),
      icon: <MdCheckCircle />,
      onClick: (e, dataItem) => {
        showActionConfirmation(dataItem, actionParams({ id: dataItem.id, path: 'complete' }), {
          actionName: 'complete',
          actionText: I18n.t('actions.done'),
          buttonColor: successBtnColor
        })
      },
      visible: (dataItem) => donePossibleStatus.includes(status[dataItem.request_status_id])
    },
    {
      text: I18n.t('actions.cancel'),
      icon: <MdClear />,
      onClick: (e, dataItem) => {
        showActionConfirmation(dataItem, actionParams({ id: dataItem.id, path: 'cancel' }), {
          actionName: 'cancel',
          actionText: I18n.t('actions.yes'),
          cancelText: I18n.t('actions.no')
        })
      },
      visible: (dataItem) => cancelPossibleStatus.includes(status[dataItem.request_status_id])
    },
    {
      text: I18n.t('actions.delete'),
      icon: <MdDelete />,
      onClick: (e, dataItem) => {
        showActionConfirmation(dataItem, actionParams(deleteParams(dataItem)), deleteActionOpts(dataItem))
      },
      visible: (dataItem) =>
        (dataItem.parent_id != null && status[dataItem.request_status_id] !== 'in_performance') ||
        status[dataItem.request_status_id] === 'registered'
    }
  ]

  const sidePanelMenuItems = [
    {
      text: I18n.t('requests.request_detail.scopes'),
      icon: <GiTargeted />,
      onClick: (e, dataItem) => {
        setPinnedRequests([dataItem])
        history.push(`/scopes`)
      },
      visible: (dataItem) => getOptionVisibility('scopings', dataItem)
    },
    {
      text: I18n.t('requests.request_detail.estimates'),
      icon: <MdAttachMoney />,
      onClick: (e, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          payload: [dataItem],
          modelName: estimateModel.paramName
        })
      },
      visible: (dataItem) => getOptionVisibility('estimates', dataItem)
    },
    {
      text: I18n.t('requests.request_detail.planning'),
      icon: <MdFlag />,
      onClick: (e, dataItem) => {
        setPinnedRequests([dataItem])
        history.push(`/plannings`)
      },
      visible: (dataItem) => getOptionVisibility('planning', dataItem)
    },
    {
      text: I18n.t('requests.request_detail.performance'),
      icon: <IoMdSpeedometer />,
      onClick: (e, dataItem) => {
        setPinnedRequests([dataItem])
        history.push(`/performances`)
      },
      visible: (dataItem) => getOptionVisibility('performance', dataItem)
    },
    {
      text: I18n.t('requests.request_detail.inspections'),
      icon: <FaCheckDouble />,
      onClick: (e, dataItem) => {
        setPinnedRequests([dataItem])
        history.push(`/inspections`)
      },
      visible: (dataItem) => getOptionVisibility('inspections', dataItem)
    },
    {
      text: I18n.t('actions.generate_work_package'),
      icon: <MdDescription />,
      onClick: (e, dataItem) => openWorkPackagePopup(dataItem),
      visible: () => true
    }
  ]

  return [contextMenuItems, sidePanelMenuItems]
}
