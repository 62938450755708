import React, { useState } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { MdSettings } from 'react-icons/md'
import { NavLink, Link } from 'react-router-dom'
import TopMenuItem from '/src/ui/core/header/top_menu_item'
import useWindowDimensions from '/src/utils/window_dimensions'
import items from '/src/ui/core/header/top_menu_items'
import EnvironmentSwitch from '/src/ui/core/menus/environment_switch'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import TopMenuReportButton from '/src/ui/core/header/top_menu_report_button'
import TopMenuDatasheetButton from '/src/ui/core/header/top_menu_datasheet_button'
import TopMenuWorkflowButton from '/src/ui/core/header/top_menu_workflow_button'
import '/src/static/css/layout.css'

export default function TopMenu({ showProjectSwitcher }) {
  const [validatedSession, setValidatedSession] = useState(false)
  const [subproject] = useStore('subproject')

  const showScaffolding = subproject.show_scaffolding

  const { width } = useWindowDimensions()
  const isShortHeader = width < 1370

  useBus(BusEvents.REMEMBER_TOKEN_UPDATED, () => setValidatedSession(true), [setValidatedSession])

  const menuItems = items(showScaffolding).map((item) => <TopMenuItem item={item} key={item.name} />)

  const shortMenuItems = items(showScaffolding)
    .slice(0, 1)
    .map((item) => <TopMenuItem item={item} key={item.name} />)

  return (
    <div className="header-menu">
      <Link to="/home">
        <img className="dpms-logo-collapsed" src="/static/svg/logo-collapsed.svg" alt="dpms-logo" />
      </Link>
      {isShortHeader ? shortMenuItems : menuItems}
      {isShortHeader && <TopMenuWorkflowButton showScaffolding={showScaffolding} />}
      <TopMenuDatasheetButton />
      {validatedSession && <TopMenuReportButton />}
      <div className="header-right-menu">
        {showProjectSwitcher && <EnvironmentSwitch />}
        <div className="btn-config">
          <NavLink to="/settings">
            <MdSettings />
          </NavLink>
        </div>
      </div>
    </div>
  )
}

TopMenu.propTypes = {
  showProjectSwitcher: PropTypes.bool
}

TopMenu.defaultProps = {
  showProjectSwitcher: true
}
