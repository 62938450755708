export const getServicesParams = (scopeToDuplicate, values, mode = 'scope') => {
  if (mode === 'scope') {
    return {
      requestAction: 'CREATE',
      httpAction: 'post',
      resourceId: scopeToDuplicate.id,
      additionalResource: { path: 'duplicate_item_services' },
      data: {
        new_parent_id: values.scope_id[0]
      }
    }
  }

  return {
    requestAction: 'CREATE',
    httpAction: 'post',
    additionalResource: { path: 'duplicate_services' },
    data: {
      new_parent_id: values.scope_id[0],
      ids_to_duplicate: scopeToDuplicate?.selected_estimate_services_ids
    }
  }
}

export const getScopeFilterQuery = (scopeToDuplicate, mode = 'scope') => {
  if (mode === 'scope') {
    return {
      where: { eav_template_id: scopeToDuplicate.eav_template_id },
      not: { id: scopeToDuplicate.id }
    }
  }

  return {
    where: { eav_template_id: scopeToDuplicate?.eav_template_id },
    not: { id: scopeToDuplicate?.parent_scope_ids }
  }
}

export const getUniqueRequestIds = (scopeToDuplicate, mode = 'scope') => {
  if (mode === 'scope') {
    return scopeToDuplicate?.request_id ? 1 : 0
  }

  if (!scopeToDuplicate?.request_ids) {
    return new Set()
  }
  return new Set(scopeToDuplicate.request_ids)
}
