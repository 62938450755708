import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'
import useFetch from '/src/hooks/api/fetch'
import LoginForm from '/src/ui/domain/login/login_form'
import LoginWrapper from '/src/ui/domain/login/login_wrapper'
import NotificationGroup from '/src/ui/core/dialogs/notification_group'
import useLogout from '/src/ui/domain/login/logout_hook'
import useClearBatchEntities from '/src/hooks/clear_batch_entities'
import useRequestsPinned from '/src/hooks/requests_pinned'
import usePreferences from '/src/ui/core/grid/preferences'
import '/src/static/css/login.css'

export default function LoginController() {
  const [loading, setLoading] = useState(false)
  const [, setUser] = useStore('user')
  const [, setUserRole] = useStore('user_role')
  const [, setGlobalProject] = useStore('project')
  const [, setGlobalSubproject] = useStore('subproject')
  const [, setToken] = useCookie('remember_token')

  const { fetch } = useFetch()
  const logout = useLogout()
  const { clearRequests } = useRequestsPinned()
  const clearBatchEntities = useClearBatchEntities()
  const { saveLocalPreferences } = usePreferences()

  const callLogin = async (formData) => {
    try {
      const sessionParams = {
        requestAction: 'CREATE',
        httpAction: 'post',
        data: formData
      }
      const { data: sessionData } = await fetch('sessions', sessionParams)
      setToken(sessionData.remember_token)
      const roleParams = {
        resourceId: sessionData.id,
        additionalResource: { path: 'role' }
      }
      const { data: roleData } = await fetch('users', roleParams)
      if (roleData) {
        setUser(sessionData)
        setUserRole([roleData])
        saveLocalPreferences(JSON.parse(sessionData.preferences))
      }
      clearBatchEntities()
    } catch (error) {
      console.error('Login failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleLogin = async (formData) => {
    setLoading(true)
    setGlobalProject({})
    setGlobalSubproject({})
    clearRequests()
    await callLogin(formData)
  }

  useEffect(() => {
    logout()
  }, [])

  return (
    <LoginWrapper>
      <React.Fragment>
        <div className="login-notification">
          <NotificationGroup />
        </div>
        <LoginForm onSubmit={handleLogin} loading={loading} />
      </React.Fragment>
    </LoginWrapper>
  )
}
