import { useState } from 'react'
import useFetch from '/src/hooks/api/fetch'

export default function useGetEstimate() {
  const [id, setId] = useState(null)
  const [statusId, setStatusId] = useState(null)

  const { fetch } = useFetch()

  const read = (requestId) => {
    const params = { query: { where: { request_id: requestId } } }

    fetch('estimates', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        if (data.length) {
          setId(data[0].id)
          setStatusId(data[0].estimate_status_id)
        }
      }
    })
  }

  return {
    id,
    statusId,
    read
  }
}
