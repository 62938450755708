import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'react-context-hook'
import { duplicationCustomActionBasic } from '/src/ui/core/forms/form_utils'
import processColumn from '/src/ui/domain/formulas_services/formulas_services_utils'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'
import useFetch from '/src/hooks/api/fetch'
import useFetchSections from '/src/hooks/api/fetch_sections'
import useFormulasServices from '/src/hooks/formulas_services'
import ProgressServiceModel from '/src/models/progress_service'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'
import { NON_EDITABLE_FORMULA_FIELD_STATUSES } from '/src/utils/constants/progress_services'
import { translateFormulaServiceColumns } from '/src/ui/domain/formulas_services/formulas_services_utils'

export default function ProgressServiceForm({ dataItem, type }) {
  const { fetch } = useFetch()  
  const {
    eav_template_id: templateId,
    discipline_id: disciplineId,
    progress_id: progressId,
    progress_service_status_id: progressServiceStatusId
  } = dataItem

  const progressServiceModel = new ProgressServiceModel()
  const entity = I18n.t('progress_services.description')

  const [completeDataItem, setCompleteDataItem] = useState(dataItem)

  const sections = useFetchSections(templateId)
  const formulasServices = useFormulasServices(templateId, true)
  const [progressServiceStatuses] = useStore('progress_service_statuses')
  const [formulasStore] = useStore('formulas')

  const sectionsWithGeneral = useMemo(() => includeGeneralSection(sections), [sections])

  const isEditMode = type === 'edit'
  const isDuplicateForm = type === 'duplicate'

  if (isEditMode) {
    const { columns } = progressServiceModel
    const contractServiceCol = columns.find((a) => a.description === 'contract_service')
    contractServiceCol.readOnly = true
  }

  const includeOnForm = () => {
    if (!isEditMode)
      return {
        eav_template_id: templateId,
        discipline_id: disciplineId,
        progress_id: progressId
      }
  }

  const formTypes = {
    new: 'form.create_entity',
    edit: 'form.update_entity',
    duplicate: 'form.duplicate_entity'
  }

  useEffect(() => {
    if (!isEditMode || (completeDataItem.pictures && completeDataItem.request)) return
    const params = {
      query: { where: { id: completeDataItem.id } }
    }

    fetch('progress_services', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        if (data) {
          setCompleteDataItem(data[0])
        }
      }
    })
  }, [completeDataItem, fetch, isEditMode])

  const itemStatusI18n =
    progressServiceStatusId && progressServiceStatuses && progressServiceStatuses[progressServiceStatusId].i18n_id

  if (!templateId || !progressId || !disciplineId) return <ThreeDotsLoader />

  if (templateId) translateFormulaServiceColumns(progressServiceModel, templateId)

  return (
    <FormWrapper
      model={progressServiceModel}
      dataItem={completeDataItem}
      label={(isEditMode || isDuplicateForm) && completeDataItem && completeDataItem.request?.id}
      type={type}
      subtitle={I18n.t(`form.subtitle`, { entity })}
      title={I18n.t(formTypes[type], { entity })}
      sidePanelSections={sectionsWithGeneral}
      isModal
    >
      <React.Fragment>
        <Form
          model={progressServiceModel}
          sections={sections}
          dataItem={completeDataItem}
          includeOnForm={includeOnForm()}
          templateId={templateId}
          type={type}
          hasFormulaServices
          formulasServices={formulasServices}
          isDuplicate={isDuplicateForm}
          disableColumnEdition={
            isEditMode && NON_EDITABLE_FORMULA_FIELD_STATUSES.includes(itemStatusI18n)
              ? (column) => column.available_on_formula
              : undefined
          }
          processColumn={(column) =>
            processColumn(column, formulasServices, formulasStore, progressServiceModel.paramName)
          }
          submitParams={isDuplicateForm ? duplicationCustomActionBasic : undefined}
        />
        <FormButtons model={progressServiceModel} type={type} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ProgressServiceForm.propTypes = {
  type: PropTypes.string,
  dataItem: PropTypes.shape({
    eav_template_id: PropTypes.number,
    discipline_id: PropTypes.number,
    progress_id: PropTypes.number,
    progress_service_status_id: PropTypes.number,
    request: PropTypes.shape({
      id: PropTypes.number
    })
  })
}

ProgressServiceForm.defaultProps = {
  type: 'new'
}
