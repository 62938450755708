import { useState, useEffect } from 'react'
import useFetch from '/src/hooks/api/fetch'
import getFirstStringOrFormula from '/src/ui/domain/eav_columns/get_first_string_or_formula'
/**
 *  This custom hook will request to api eav_columns at an template and return the first by position
 *
 * @param templateId - id of eav_template to get columns
 * @returns {string} first column of type string or formula
 */
export default function useFetchDescription(templateId) {
  const { fetch } = useFetch()
  const [firstColumn, setFirstColum] = useState()

  useEffect(() => {
    if (!templateId) return
    const params = {
      query: {
        where: { eav_template_id: templateId },
        order: { position: 'asc' }
      }
    }

    fetch('eav_columns', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        if (!data.length) return

        const firstColumn = getFirstStringOrFormula(data)

        if (!firstColumn) return

        setFirstColum(firstColumn.description)
      }
    })
  }, [templateId])

  return firstColumn
}
