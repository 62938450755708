import React from 'react'
import PropTypes from 'prop-types'
import ScaffoldingPartActionCard from '/src/ui/domain/scaffoldings/scaffolding_part_action_card'
import '/src/static/css/core/lists/feed_list.css'

const cardItemSwitch = (component, item, index, parentDataItem) => {
  switch (component) {
    case 'scaffolding_part_action_block':
      return <ScaffoldingPartActionCard key={index} action={item} parentDataItem={parentDataItem} />
    default:
      return ''
  }
}

export default function FeedListBlock({ list, component, emptyMessage, parentDataItem }) {
  return (
    <React.Fragment>
      {list && list.length > 0 ? (
        <div className="feed-list">
          <div className="feed-container">
            <div className="feed-wrapper">
              <div className="feed-vertical-bar" />
              <div className="feed-items">
                {list.map((item, index) => cardItemSwitch(component, item, index, parentDataItem))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="feed-empty">{emptyMessage}</div>
      )}
    </React.Fragment>
  )
}

FeedListBlock.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array]),
  component: PropTypes.string.isRequired,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  parentDataItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}

FeedListBlock.defaultProps = {
  list: undefined,
  emptyMessage: ''
}
