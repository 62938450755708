import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MdCheck, MdClose, MdFilterAlt, MdViewStream } from 'react-icons/md'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import I18n from '/src/utils/translations'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import PopupSearch from '/src/ui/core/popups/popup_search'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/core/blocks/timeline_filter.css'

export default function InspectionTimelineFilters({
  filter,
  setFilter,
  filterGroup,
  setFilterGroup,
  indexifiedTemplates,
  inspectionCategories
}) {
  const [filterSearch, setFilterSearch] = useState('')
  const [isCategoryFilterOpen, setIsCategoryFilterOpen] = useState(false)
  const [isTemplateFilterOpen, setIsTemplateFilterOpen] = useState(false)

  const categoryFilterButtonRef = useRef(null)
  const templateFilterButtonRef = useRef(null)

  const resetFilterPopup = () => {
    setIsCategoryFilterOpen(null)
    setIsTemplateFilterOpen(null)
    setFilterSearch('')
  }

  const handleSelectCategory = (categoryId) => {
    if (filterGroup !== 'category') {
      setFilterGroup('category')
      setFilter([categoryId])
    } else if (filter.includes(categoryId)) {
      const newFilter = filter.filter((item) => item !== categoryId)
      setFilter(newFilter)
      if (newFilter.length === 0) setFilterGroup(null)
    } else {
      const newFilter = [...filter, categoryId]
      setFilter(newFilter)
    }

    resetFilterPopup()
  }

  const handleSelectTemplate = (templateTitle) => {
    if (filterGroup !== 'template') {
      setFilterGroup('template')
      setFilter([templateTitle])
    } else if (filter.includes(templateTitle)) {
      const newFilter = filter.filter((item) => item !== templateTitle)
      setFilter(newFilter)
      if (newFilter.length === 0) setFilterGroup(null)
    } else {
      const newFilter = [...filter, templateTitle]
      setFilter(newFilter)
    }
    resetFilterPopup()
  }

  const removeAllFilters = () => {
    setFilter([])
    setFilterGroup(null)
    resetFilterPopup()
  }

  const buildCategoryFilterOptions = () => {
    if (!inspectionCategories || isEmpty(inspectionCategories)) return (
      <div className='timeline-filter__empty-state'>
        {I18n.t('side_panel.tabs.no_categories')}
      </div>
    )

    return (
      <div className="timeline-filter__popup-body" data-testid="category-filter-popup">
        <PopupSearch onChange={(e) => setFilterSearch(e.target.value.toLowerCase())} />
        {Object.values(inspectionCategories).map((category) => {
          if (filterSearch && !category.description.toLowerCase().includes(filterSearch)) return null

          const isActive = filter.includes(category.id)
          const additionalClassNames = isActive ? 'timeline-filter__option--active' : ''

          return (
            <div
              key={category.description}
              onClick={() => handleSelectCategory(category.id)}
              className={`timeline-filter__option ${additionalClassNames}`}
            >
              {category.description}
              <span className="timeline-filter__option-check">{isActive && <MdCheck />}</span>
            </div>
          )
        })}
      </div>
    )
  }

  const buildTemplateFilterOptions = () => {
    if (!indexifiedTemplates || isEmpty(indexifiedTemplates)) return (
      <div className='timeline-filter__empty-state'>
        {I18n.t('side_panel.tabs.no_templates')}
      </div>
    )

    return (
      <div className="timeline-filter__popup-body" data-testid="filter-popup">
        <PopupSearch onChange={(e) => setFilterSearch(e.target.value.toLowerCase())} />
        {Object.values(indexifiedTemplates).map((template) => {
          if (filterSearch && !template.title.toLowerCase().includes(filterSearch)) return null

          const isActive = filter.includes(template.title)
          const additionalClassNames = isActive ? 'timeline-filter__option--active' : ''

          return (
            <div
              key={template.title}
              onClick={() => handleSelectTemplate(template.title)}
              className={`timeline-filter__option ${additionalClassNames}`}
            >
              {template.title}
              <span className="timeline-filter__option-check">{isActive && <MdCheck />}</span>
            </div>
          )
        })}
      </div>
    )
  }

  const buildFilterLabelValues = () => {
    if (filterGroup === 'category') {
      return filter.map((categoryId) => inspectionCategories[categoryId].description).join(', ')
    }

    return filter.join(', ')
  }

  return (
    <div className="timeline-filter">
      {!isEmpty(filter) && (
        <div className="timeline-filter__filtered-label">
          <span className="timeline-filter__filtered-text">
            {`${I18n.t(`side_panel.tabs.${filterGroup}_filter`)}: `}
            {buildFilterLabelValues()}
          </span>
          <MdClose onClick={removeAllFilters} data-testid="clear-timeline-filters" />
        </div>
      )}
      <PopupAnchored
        body={buildCategoryFilterOptions()}
        popupButtonRef={categoryFilterButtonRef}
        anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}
        forceOpen={isCategoryFilterOpen}
        setForceOpen={() => isCategoryFilterOpen}
        onClickOutside={() => setIsCategoryFilterOpen(false)}
      >
        <CustomTooltip position="top" parentTitle>
          <span
            title={I18n.t('side_panel.tabs.category_filter')}
            className={classNames(
              'timeline-filter__button',
              filterGroup === 'category' && 'timeline-filter__button--active'
            )}
            ref={categoryFilterButtonRef}
            onClick={() => setIsCategoryFilterOpen((previous) => !previous)}
            data-testid="timeline-category-filter-button"
          >
            <MdViewStream />
          </span>
        </CustomTooltip>
      </PopupAnchored>
      <PopupAnchored
        body={buildTemplateFilterOptions()}
        popupButtonRef={templateFilterButtonRef}
        anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}
        forceOpen={isTemplateFilterOpen}
        setForceOpen={() => isTemplateFilterOpen}
        onClickOutside={() => setIsTemplateFilterOpen(false)}
      >
        <CustomTooltip position="top" parentTitle>
          <span
            title={I18n.t('side_panel.tabs.template_filter')}
            className={classNames(
              'timeline-filter__button',
              filterGroup === 'template' && 'timeline-filter__button--active'
            )}
            ref={templateFilterButtonRef}
            onClick={() => setIsTemplateFilterOpen((previous) => !previous)}
            data-testid="timeline-template-filter-button"
          >
            <MdFilterAlt />
          </span>
        </CustomTooltip>
      </PopupAnchored>
    </div>
  )
}

InspectionTimelineFilters.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setFilter: PropTypes.func.isRequired,
  filterGroup: PropTypes.string,
  setFilterGroup: PropTypes.func.isRequired,
  indexifiedTemplates: PropTypes.oneOfType([PropTypes.object]),
  inspectionCategories: PropTypes.oneOfType([PropTypes.object]),
}

InspectionTimelineFilters.defaultProps = {
  filter: [],
  filterGroup: null,
  indexifiedTemplates: undefined,
  inspectionCategories: undefined
}
